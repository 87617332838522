import { ACADEMIC_PROGRAMS, GRADUATE_CONTACT_SEARCH, FORMS_SEARCH, SEARCH_PROGRAM_MAP, GRAD_RECORDS_SEARCH, DEGREES, TERMS, FACULTY_SEARCH } from "../actions-index.js";

export function academicPrograms(state = {}, action = {}) {
    return (action.type === ACADEMIC_PROGRAMS ? action.payload : state);
}

export function searchProgramMap(state = {}, action = {}) {
    return (action.type === SEARCH_PROGRAM_MAP ? action.payload : state);
}

export function graduateContactSearch(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACT_SEARCH ? action.payload : state);
}

export function facultySearch(state = {}, action = {}) {
    return (action.type === FACULTY_SEARCH ? action.payload : state);
}

export function formsSearch(state = {}, action = {}) {
    return (action.type === FORMS_SEARCH ? action.payload : state);
}

export function graduationRecordsSearch(state = {}, action = {}) {
    return (action.type === GRAD_RECORDS_SEARCH ? action.payload : state);
}

export function degrees(state = {}, action = {}) {
    return (action.type === DEGREES ? action.payload : state);
}

export function terms(state = {}, action = {}) {
    return (action.type === TERMS ? action.payload : state);
}