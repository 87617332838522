import { Mui } from "@osu/react-ui";
import React, { useContext, useEffect } from "react";
import { inputMappings, loggedinUserData } from "../../../../util/enums";
import TextInput from "../../Inputs/TextInput";
import Address from "./Address";
import { AppContext } from "../../../../App/context"

function Person(props) {
  const { fetchData, ...filteredProps } = props;
  const { type, user, getStudentInfo, studentid, id, onChange, value } = filteredProps;
  const { mailingAddress, className, cumUndergradGpa, phone, addressLine1, ...rest } = user
  const authentication = useContext(AppContext)

  useEffect(() => {
    if(fetchData) {
      getStudentInfo(studentid)
    }
  }, [fetchData, getStudentInfo, studentid])

  useEffect(() => {
    if((type === loggedinUserData.mailingAddress) 
      && mailingAddress?.address1 
      && (mailingAddress?.address1 !== value?.address1)) {
      onChange({
        id,
        value: {
          ...mailingAddress,
          name: rest?.name
        }
      })
    }
  }, [id, mailingAddress, onChange, type, value, rest?.name, props])

  switch (type) {
    case loggedinUserData.mailingAddress:
      return (
        <Address
          id={id}
          className={`padding-2${className ? ` ${className}` : ""}`}
          {...(mailingAddress || {})}
          {...rest}
        />
      );
    case loggedinUserData.cumUndergradGpa:
      return (
        <Mui.Box className={className} {...rest}>
          <Mui.Typography component="p" variant="subtitle2">
            CUM Undergrad GPA
          </Mui.Typography>
          <Mui.Typography>{cumUndergradGpa}</Mui.Typography>
        </Mui.Box>
      );
    case inputMappings.street:
    case inputMappings.phone:
      return (
        <TextInput
        defaultValue={user[type]}
        {...filteredProps}
        type={type === inputMappings.phone ? "tel" : "text"}
        />
      );
    default:
      return null;
  }
}

export default Person;
