import { Mui } from "@osu/react-ui";

const progress = {
  label: "In Progress"
}
const notStarted = {
  label: "Not Started"
}

const programReview = {
  reviewed: {
    Icon: Mui.CheckCircleIcon,
    label: "Reviewed"
  },
  progress,
  notStarted,
  followUp: "followUp"
}

const gradSchool = {
  progress,
  notStarted,
}

export {
  programReview,
  gradSchool
}