import { getFormByType } from "../Forms/paths";

function buildFormDetailFields(form, dataFields = []) {
  console.log("buildFormDetailFields form ", form);
  console.log("buildFormDetailFields dataFields ", dataFields);
    const formDetailFields = [];
    for(const dataField of dataFields) {
      const tokens = dataField.split(".");
      const field = tokens[0];
      const nestedField = (tokens.length > 1 ? tokens[1] : null);
      let formField;
      if(nestedField) {
        if(Array.isArray(form[field])) {
          formField = form[field][0][nestedField];
        } else {
          formField = form[field][nestedField];
        }
      } else {
        formField = form[field];
      }
      if(formField?.label) formDetailFields.push(formField);
    }
    return formDetailFields;
  }

  function transformSearchForms(searchForms = []) {
    console.log("transformSearchForms searchForms ", searchForms);
    const forms = [];
    for(const searchForm of searchForms) {
      const formDefinition = getFormByType(searchForm.formType);
      console.log("transformSearchForms formDefinition is ", formDefinition);
      if(formDefinition) {
        forms.push({
          formId: searchForm.id,
          name: searchForm.name,
          osuid: searchForm.studentid,
          form: formDefinition.title,
          formDetailFields: buildFormDetailFields(searchForm, formDefinition?.formDetailFields),
          createdBy: searchForm.createdBy,
          createdDate: searchForm.createdDate,
          status: searchForm.status,
          formType: formDefinition.formType,
          academicPlan: searchForm.academicPlan
        });
      }
    }
    console.log("returning transformSearchForms forms ", forms);
    return forms;
  }

  function transformSearchGradRecs(searchGraduationRecords = [], filter = {}) {
    const records = [];
    for(let searchRecord of searchGraduationRecords) {
      records.push({
          osuid: searchRecord.emplid,
          name: searchRecord.name,
          email: searchRecord.email,
          degree: searchRecord.degree,
          degreeNumber: searchRecord.degreeNumber,
          academicPlan: searchRecord.academicPlan,
          program: searchRecord.gradProgram,
          programDescription: searchRecord.programDescription,
          completionTerm: searchRecord.completionTerm,
          academicCareer: searchRecord.academicCareer,
          transcriptDescription: searchRecord.transcriptDescription
        });
      }
      let filteredRecords = [];
      if (Object.keys(filter).length > 0) {
        for (const key of Object.keys(filter)) {
          filteredRecords = records.filter(reccord => reccord[`${key}`] !== filter[`${key}`]);
        }
        return filteredRecords;
      } else {
        console.log("returning transformSearchGradRecs records ", records);
        return records;
      }
  }
 
  export { buildFormDetailFields, transformSearchForms, transformSearchGradRecs };
  