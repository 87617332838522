import React, { Fragment, Suspense, useContext } from "react";
import { Route, Routes as RouteList } from "react-router-dom";
import PageNotFound from "../views/PageNotFound";
import PATHS, { EXTERNAL_PATHS, authenticationFlowPaths } from "../paths";
import RenderableView from "./RenderableView";
import { AppContext } from "../../App/context";
import withErrorBoundary from "../../Common/hocs/withErrorBoundary";
import withRouter from "../../Common/hocs/withRouter";

function Routes(props) {
  const appContext = useContext(AppContext)
  const { external } = appContext;

  let RoutesToRender = (external ? EXTERNAL_PATHS : PATHS).map((pathInformation, index) => {
    return  <Route
      key={`route${encodeURIComponent(pathInformation.title)}#${index}`}
      path={pathInformation.path}
      element={<RenderableView pathInformation={pathInformation} />}
    />
  })

  return (
    <Fragment>
      <Suspense fallback={<div>Loading</div>}>
        <RouteList>
          {authenticationFlowPaths.map(({ path, Component }, index) =>  {
            return <Route key={`route${encodeURIComponent(path)}#${index}`} path={path} element={<Component />} />
          })}
          {RoutesToRender}
          <Route element={<PageNotFound />} />
        </RouteList>
      </Suspense>
    </Fragment>
  );

  
}


export default withRouter(withErrorBoundary(Routes));