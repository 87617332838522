import { Mui, MuiIcons, MuiStyles } from "@osu/react-ui";
import React, { useEffect, useState } from "react";
import { ACTION_STATUS, TASK_ACTION } from "../../util/constants";
import { formatValue } from "../../util/functions";

function History(params) {
  const { completeTask, getHistory, emplid, formId, formType, columns, history, isAdmin, status, error, setErrorMessage, resetTaskAction, taskActionStatus } = params;
  const isPerformingTaskAction = (taskActionStatus === ACTION_STATUS.LOADING);

  useEffect(() => {
    !status && formId && getHistory({ emplid, formType, id: formId });
  }, [emplid, formId, formType, getHistory, status])

  useEffect(() => {
    setErrorMessage(existing => {
      if(!error && existing) {
        return ""
      } else if (error) {
        return error
      }
    })
  }, [error, setErrorMessage])

  // when the task action completes, get updated history
  useEffect(() => {
    if(taskActionStatus === ACTION_STATUS.SUCCESS) getHistory({ emplid, formType, id: formId })
  }, [emplid, formId, formType, getHistory, taskActionStatus]);

  // when the task action fails, set error message
  useEffect(() => {
    if(taskActionStatus === ACTION_STATUS.ERROR) setErrorMessage("Failed to perform Task Action.");
  }, [setErrorMessage, taskActionStatus]);

  // on unmount, reset state
  useEffect(() => {
    return () => {
      resetTaskAction();
      setErrorMessage("");
    };
  }, [resetTaskAction, setErrorMessage]);

  if(status === ACTION_STATUS.LOADING) {
    const columnWidths = ["25%", "20%", "15%", "25%", "15%"];
    const TableCells = columnWidths.map((columnWidth, index) => {
      return (<Mui.TableCell key={index} width={columnWidth}><Mui.Skeleton /></Mui.TableCell>);
    });
    return (
      <div>
        <div aria-live="assertive" className="sr-only">Loading History, please wait...</div>
        <Mui.Stepper orientation="vertical">
          {[...Array(1)].map((item, index) => (
            <Mui.Step active key={`step-${index}`}>
              <Mui.StepLabel StepIconComponent={(props) => null}><Mui.Skeleton width="20%" /></Mui.StepLabel>
              <Mui.StepContent>
                <Mui.Table>
                  <Mui.TableHead><Mui.TableRow>{TableCells}</Mui.TableRow></Mui.TableHead>
                  <Mui.TableBody><Mui.TableRow>{TableCells}</Mui.TableRow></Mui.TableBody>
                </Mui.Table>
              </Mui.StepContent>
            </Mui.Step>
          ))}
        </Mui.Stepper>
      </div>
    );
  }

  if(status === ACTION_STATUS.SUCCESS && history.length === 0) {
    return (
      <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
         <Mui.Typography component="span" variant="subtitle2" aria-live="assertive">No History</Mui.Typography>
      </Mui.Box>
    );
  }

  return (
    <div>
      {isPerformingTaskAction &&
          <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Mui.CircularProgress aria-describedby="performingTaskAction" />
              <Mui.Typography id="performingTaskAction" variant="body1" aria-live="assertive" >
                  Performing Task Action, please wait...
              </Mui.Typography>
          </Mui.Box>
      }
      <Mui.Stepper orientation="vertical" style={{...(isPerformingTaskAction ? { display: "none" } : {})}}>
        {history.map((record = {}, index) => {
          const { step: label, status, items: rows } = record
          const key = `${encodeURIComponent(label)}-step-${index}`

          return (
            <Mui.Step active key={key}>
              <Mui.StepLabel StepIconComponent={(props) => <Icon {...props} status={status} />}>{label}</Mui.StepLabel>
              <Mui.StepContent>
                <Mui.Table aria-label="history">
                  <Mui.TableHead>
                    <Mui.TableRow>
                      {columns.map((col, index) => {
                        return <Mui.TableCell key={index} width={col.width} align="left">{col.label}</Mui.TableCell>
                      })}
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {rows.map((row, rowIndex) => (
                      <Mui.TableRow key={rowIndex}>
                        {columns.map((col, colIndex) => {
                          const props = (colIndex === 0  ? { component: "th", scope: "row" }  : {});
                          const value = row[col.dataField];
                          return (
                            <Mui.TableCell key={colIndex} width={col.width} align="left" {...props}>
                              {colIndex === 4 ? 
                                (<TaskActions index={rowIndex} isAdmin={isAdmin} label={col.label} value={value} complete={() => {
                                  completeTask({ emplid, formId, approvalTask: row.task, approverEmplid: row.emplid})}
                                } />) :
                                formatValue(value)}
                            </Mui.TableCell>
                          );
                        })}
                      </Mui.TableRow>
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.StepContent>
            </Mui.Step>
          );
        })}
      </Mui.Stepper>
    </div>
  );
}

History.defaultProps = {
  history: []
}

const useStyles = () => {
  const theme = MuiStyles.useTheme();
  return {  
    icon: {
      padding: ".1em",
      fontSize: "2rem",
    },
    cancel: {
      color: theme.palette.error.main
    },
    check: {
      color: theme.palette.success.main
    },
    warning: {
      color: theme.palette.warning.main
    },
    progress: {
      color: theme.palette.secondary.main
    },
    default: {
      color: theme.palette.secondary.light
    },
    taskActionsSelect: {
      minWidth: "9rem",
      width: "fit-content"
    }
  };
};

function Icon({status, ...rest}) {
  const styles = useStyles()

  if(status === "CANCELLED") {
    return (<MuiIcons.Cancel ariaLabel="Cancelled" classes={{ root: styles.cancel }} />);
  } else if(status === "COMPLETE") {
    return <MuiIcons.CheckCircle {...rest} classes={{ root: styles.check }} />
  } else if (status === "IN_PROGRESS") {
    return <MuiIcons.WatchLater classes={{ root: styles.progress }}/>
  } else if (status === "DELAYED") {
    return <MuiIcons.Warning classes={{ root: styles.warning }} />
  }
  return <MuiIcons.RadioButtonUnchecked classes={{ root: styles.default }} />
}

function TaskActions(props) {
  const { complete, index = 0, isAdmin = false, label = "Task Actions", value: taskActions = [] } = props;
  const [selectedTaskAction, setSelectedTaskAction] = useState("");
  const styles = useStyles();

  if(isAdmin && taskActions.length > 0) {
    const id = `taskActionsSelect${index}`;
    const onChange = (e) => {
      const selectedValue = e.target.value
      setSelectedTaskAction(selectedValue);

      if(selectedValue !== "") {
        switch(TASK_ACTION[selectedValue]) {
          case TASK_ACTION.COMPLETE:
            complete();
            break;
          default:
            console.log(`Change event not found for Task Action ${selectedValue}`);
        }
      }
    };
    return (
      <Mui.FormControl variant="outlined" className={styles.taskActionsSelect}>
        <Mui.InputLabel htmlFor={id}>{label}</Mui.InputLabel>
        <Mui.Select native inputProps={{ id }} value={selectedTaskAction} onChange={onChange}>
          <option aria-label="None" value="" />
          {taskActions.map((taskAction) => {
              const taskActionLabel = TASK_ACTION[taskAction];
              return (taskActionLabel ? <option key={taskAction} value={taskAction}>{taskActionLabel}</option> : null);
          })}
        </Mui.Select>
      </Mui.FormControl>
    );
  }
  return null;
}

export default History;
