import { connect } from 'react-redux';
import { getExternalUserInfo } from "../../actions-index";
import withRouter from "../../Common/hocs/withRouter";
import ExternalLogIn from "../components/ExternalLogIn";

const mapStateToProps = (state, ownProps) => {
    const { authentication } = state

    return {
        state: state,
        user: authentication.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      getExternalUserInfo: (ukey, akey) => dispatch(getExternalUserInfo(ukey, akey))
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExternalLogIn));
