import React, { useState } from "react";
import { FileUploader, Mui } from "@osu/react-ui";

function FileUpload(props = {}) {
  const { onChange, id, readOnly, error, value, label, ...rest } = props;
  const [progress, setProgress] = useState(0)

  return (
    <React.Fragment>
      {label && <Mui.FormLabel>{label}</Mui.FormLabel>}
      <FileUploader
        disabled={readOnly}
        onFileClick={(values) => console.log('onFileClick', values)}
        errorText={error}
        defaultValue={value}
        id={id}
        preventOutOfBounds
        onFileUpload={(file) => {
          onChange({
              id,
              value: file
          })
          setProgress(100)
      }}
        progress={progress}
        setProgress={setProgress}
        onDelete={(values) => console.log('onDelete', values)}
        helperTextProps={{
          variant: 'body2',
          className: 'margin-y-1'
        }}
        {...rest}
      />
    </React.Fragment>
  );
}

export default FileUpload;
