import { Mui, usePrevious } from "@osu/react-ui";
import React, { Fragment, useEffect, useState } from "react";

function TextInput(props = {}) {
  const { id, value, className, required, onChange, title, defaultValue, type, error: formError, skType, dataField, gridProps, filterState, summary, getStudentInfo, ...rest } = props;
  const [shrink, setShrink] = useState(!!value)
  const previousDefaultValue = usePrevious(defaultValue)
  const [error, setError] = useState(formError)

  const onBlur = () => {
    if(type === "tel" && value) {
      const phoneLength = value.match(/(\d)/g).length
      setError(phoneLength === 10 
        ? ""
        : "Please enter a valid 10 digit phone number."
        )
    }
    setShrink(!!value)
  }

  useEffect(() => {
    if(onChange && !previousDefaultValue && !value && defaultValue) {
      onChange({
        id,
        value: defaultValue
      });
    }
  }, [defaultValue, id, onChange, previousDefaultValue, value])

  useEffect(() => {
    setError(err => {
      if(err !== formError) {
          return formError
       }
      return err
    });
  }, [formError]);

  return <Fragment>
    <Mui.TextField
      id={id}
      value={value ?? ""}
      className={className}
      required={required}
      error={!!error}
      onFocus={() => {
        setShrink(true)
      }}
      onBlur={onBlur}
      onChange={(e) => {

        onChange({
          id,
          value: e.target.value,
        });
      }}
      label={title}
      type={type}
      InputLabelProps={{ shrink: !!value || shrink }}
      {...rest}
      helperText={error}
    />
    {summary && <Mui.Typography component="div" className={`margin-bottom-1 ${className}`} variant="body2">{summary}</Mui.Typography>}
  </Fragment>;
}

TextInput.defaultProps = {
  variant: "outlined",
  value: ""
};

export default TextInput;
