import React from "react";
import { getDisplayName } from "../../util/functions";

const mutateOptions = (options = [], filter = [], sectionState = {}) =>  {   
  let arr = Array.isArray(options)
    ? options
    : []
  if(!filter?.length) {
    return arr
  }
  filter.forEach((vals) => {
    const { field, match, operation = "equals" } = vals;
    const correspondingState = sectionState?.[field]?.value
    arr = arr.filter(item => {
      const stateValue = (correspondingState?.match ?? correspondingState?.value);
      switch(operation) {
        case "includes":
          return item?.[match] && item?.[match].includes(stateValue)
        case "equals":
          return item?.[match] && (item?.[match] === stateValue);
        default:
          return false;
      }
    })

  })

  return arr
}

function withOptionsFilter(WrappedComponent) {
  function _withOptionsFilter(props) {
    const { options, filter, filterState, sectionState, dataField, ...rest } = props
    
    return <WrappedComponent 
        {...rest}
        options={mutateOptions(options, filter, sectionState)}
    />
  }
  _withOptionsFilter.displayName = `WithDropdownOptions(${getDisplayName(
    WrappedComponent
  )})`;
  return _withOptionsFilter;
}
export default withOptionsFilter;
