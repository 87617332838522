import { useCallback, useEffect, useRef, useState } from 'react'
import { OSU_NAV_ID, NAVBAR_WRAPPER_ID } from '../../util/constants'

const calculateTotalHeight = () => {
    const navbarRect = document.getElementById(OSU_NAV_ID)?.getBoundingClientRect() || {}
    const headerRect = document.getElementById(NAVBAR_WRAPPER_ID)?.getBoundingClientRect() || {}
    const headerRectHeight = isNaN(headerRect.height) ? 0 : headerRect.height

    let navbarHeight = navbarRect.height
    const navbarHeightY = navbarRect.y
    if(navbarHeightY < 0) {
        navbarHeight = navbarHeight + navbarHeightY
    }
    if(isNaN(navbarHeight) || navbarHeight < 0) navbarHeight = 0
    const calculatedHeight = navbarHeight + headerRectHeight
    return calculatedHeight
}

const updateNavPropertyAndReturnHeight = () => {
    const calculatedHeight = calculateTotalHeight()
    const root = document.body || document.documentElement
    let aboveZeroHeight = isNaN(calculatedHeight) ? 0 : calculatedHeight
    if(calculatedHeight < 0) aboveZeroHeight = 0

    const existingHeight = root.style.getPropertyValue('--nav-height')
    const parsedExistingHeight = Number(existingHeight.replace('px', ''))
    
    const roundedDifference = Math.abs(parsedExistingHeight - aboveZeroHeight);
    const newNavheight = isNaN(calculatedHeight) ? 0 : calculatedHeight
    const newHeightProperty = aboveZeroHeight + 'px'

    if((roundedDifference > 5 && newNavheight > 0) || (aboveZeroHeight === 0 && parsedExistingHeight > 0)) {
        root.style.setProperty('--nav-height', newHeightProperty)
    } 
    return aboveZeroHeight
}

const useCssVariableUpdater = () => {
    const scrollTimeout = useRef()
    const calculatedHeight = updateNavPropertyAndReturnHeight()
    const [navHeight, setNavHeight] = useState(calculatedHeight)

    const handleScroll = useCallback(() => {
        clearTimeout(scrollTimeout.current);
        
        scrollTimeout.current = setTimeout(() => {
          const recalculatedHeight = updateNavPropertyAndReturnHeight()

          if(navHeight !== recalculatedHeight) {
            setNavHeight(recalculatedHeight)
          }
        }, 50);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, {
          capture: false,
          passive: true,
        });
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

    return {
      navHeight
    }
}
export default useCssVariableUpdater