import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers-index";

export default function configureStore(initialState) {
  let middlewares = [thunk];
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
  }

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares)
  );
}
