import { Mui } from "@osu/react-ui";

export const BlackIconButton = ({ children, ...props }) => (
  <Mui.IconButton sx={{
    "&": {
      color: Mui.colors.grey[900]
    }
  }} {...props}>{children}</Mui.IconButton>
);

export const GreenButton = ({ children, ...props }) => (
  <Mui.Button sx={{
    "&": {
      color: "white",
      backgroundColor: Mui.colors.green[500],
      '&:hover': {
        backgroundColor: Mui.colors.green[700],
      }
    }
  }} {...props}>{children}</Mui.Button>
);

export const GreenIconButton = ({ children, ...props }) => (
  <Mui.IconButton sx={{
    "&": {
      color: Mui.colors.green[500]
    }
  }} {...props}>{children}</Mui.IconButton>
);

export const RedButton = ({ children, ...props }) => (
  <Mui.Button sx={{
    "&": {
      color: "white",
      backgroundColor: Mui.colors.red[500],
      '&:hover': {
        backgroundColor: Mui.colors.red[700],
      }
    }
  }} {...props}>{children}</Mui.Button>
);

export const RedIconButton = ({ children, ...props }) => (
  <Mui.IconButton sx={{
    "&": {
      color: Mui.colors.red[500]
    }
  }} {...props}>{children}</Mui.IconButton>
);

export const BlueButton = ({ children, ...props }) => (
  <Mui.Button sx={{
    "&": {
      color: "white",
      backgroundColor: Mui.colors.blue[500],
      '&:hover': {
        backgroundColor: Mui.colors.blue[700],
      }
    }
  }} {...props}>{children}</Mui.Button>
);

export const OrangeButton = ({ children,...props }) => (
  <Mui.Button sx={{
    "&": {
      color: "white",
      backgroundColor: Mui.colors.orange[500],
      '&:hover': {
        backgroundColor: Mui.colors.orange[700],
      }
    }
  }} {...props}>{children}</Mui.Button>
);