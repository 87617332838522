import { createId } from "../../../util/functions"

export const updateInputGroupState = (state = [], action = {}) => {
    let newState = [...state]
    const appendNewItem = () => {
      newState.push({
        id: createId('input-group')
      })
    }
  
    switch (action.type) {
      case "ADD": {
        appendNewItem()
        return newState;
      }
      case "UPDATE": {
        const { onChangeHandler } = action?.payload?._options || {}
        newState = state.map(row => {
          if(row.id === action?.payload?.id) {
            return {
              ...row,
              ...action?.payload,
              dataFieldMapping: {
                ...row?.dataFieldMapping || {},
                ...action?.payload?.dataFieldMapping || {},
              }
            }
          }
          return row
        })
        if(newState?.[0]?.initial) {
          newState[0].initial = false
        }
        onChangeHandler && onChangeHandler(newState)
        return newState
      }
      case "DELETE": {
        const { onChangeHandler } = action?.payload?._options || {}
        newState = newState.filter(row => row.id !== action?.payload?.id)
        if(newState.length === 0) {
          appendNewItem()
        }
        onChangeHandler && onChangeHandler(newState)
        return newState;
      }
      case "RESET": {
        if(newState?.length === 1 && newState?.[0]?.initial) {
          return action?.payload
        }
        return state
      }
      default:
        console.log('Received unknown action type', action.type)
        return state
    }
  }
  