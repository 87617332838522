import { connect } from 'react-redux';
import AuthButton from "../components/AuthButton";
import withRouter from "../../Common/hocs/withRouter";

const mapStateToProps = (state, ownProps) => {
    const { authentication } = state
    const { status = '' } = authentication

    return {
        status,
        name: authentication.user?.name
    };
};

export default withRouter(connect(mapStateToProps, null)(AuthButton));
