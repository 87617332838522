import { connect } from 'react-redux';
import App from '../components';
import withRouter from "../../Common/hocs/withRouter";

const mapStateToProps = (state) => {
    const { authentication } = state
    const { status = '' } = authentication
    const isExternal = authentication?.user?.isExternal || ""

    return {
        authStatus: status,
        isLoggedIn: status === 'success',
        external: isExternal
    };
};

export default withRouter(connect(mapStateToProps, null)(App));