import { useState, useEffect, useCallback } from "react";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useDispatch, useSelector } from "react-redux";
import { receiveAmplifyHubUser, setUserStatus } from "../../actions-index";
import { redirectToLogIn } from "../../util/login";
import { useNavigate, useLocation } from "react-router-dom";
import { isUnauthenticated } from "../../util/functions";
import { authenticationFlowPaths } from "../../Routes/paths";
import { ACTION_STATUS } from "../../util/constants";

const CHANNELS = {
  auth: "auth",
  core: "core",
};

const EVENTS = {
  customOAuthState: "customOAuthState",
  signIn: "signIn",
  signOut: "signOut",
};

export default function useAmplifyHub() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [triggerHubAuthUseEffect, setTriggerHubAuthUseEffect] = useState(false);
  const [localUser, setLocalUser] = useState({});
  const reduxAuth = useSelector(state => {
    return state?.authentication
  });
  const reduxUser  = reduxAuth?.user

  useEffect(() => {
    if(localUser?.osuid && (localUser?.osuid !== reduxAuth?.user?.osuid)) {
      dispatch(receiveAmplifyHubUser(localUser));
    }
  }, [dispatch, localUser, reduxAuth])
  const location = useLocation();
  const pushRedirectTo = navigate

  const navigateToRedirectPath = useCallback((path) => {
    if (path) {
      const redirectExclusions = authenticationFlowPaths.map((paths) => ({ path: paths?.path }));
      let redirectPath = '/'
      
      const splitPaths = path.split('?')
      if(!!path && path.startsWith('/') && splitPaths[0] &&  !redirectExclusions.includes(splitPaths[0])) {
        redirectPath = path
      }
      
      if(location?.pathname !== redirectPath) {
        pushRedirectTo(redirectPath);
      }
    }
  }, [location?.pathname, pushRedirectTo])

  const manageHubSignIn = useCallback((payload) => {
    const user = payload?.data?.signInUserSession?.idToken?.payload
    setTriggerHubAuthUseEffect(true);
    if(user) {
      setLocalUser(user);
    } else {
      dispatch(receiveAmplifyHubUser({ status: 'error' }));
    }
  }, [dispatch])

  const manageHubSignOut = useCallback(() => {
    dispatch(receiveAmplifyHubUser({ status: 'cleared' }))
  }, [dispatch])

  useEffect(() => {
    let isMounted = true;

    const HubListener = () => {
      return Hub.listen(CHANNELS.auth, (data) => {
        onAuthEvent(data?.payload);
      });
    };

    const onAuthEvent = (payload) => {
      if (!isMounted) {
        return
      }
      switch (payload?.event) {
        case EVENTS.customOAuthState:
          navigateToRedirectPath(payload?.data)
          break;
        case EVENTS.signIn:
          manageHubSignIn(payload)
          break;
        case EVENTS.signOut:
          manageHubSignOut()
          break;
        default:
          return;
      }
    };

    const hubListenerCancel = HubListener();

    return () => {
      hubListenerCancel();
      isMounted = false;
    };
  }, [triggerHubAuthUseEffect, manageHubSignIn, manageHubSignOut, navigateToRedirectPath]);

  const handleSignIn = useCallback(async (redirectPath, options = {}) => {
    const { doNotRedirect = false } = options
    dispatch(setUserStatus(ACTION_STATUS.LOADING))

    try {
      /*
        Check for the user before pulling the session because if there is no user there is
        no session token and we end up in an endless "Improper user session" error loop.
        When there is no user a UserUnAuthenticatedException is thrown, which we handle.
      */
      await getCurrentUser();
      const session = await fetchAuthSession();
      const { idToken } = (session.tokens ?? {});
      if (!idToken?.payload?.osuid) {
        throw new Error("Improper user session");
      } else if(!reduxUser) {
        setLocalUser(idToken.payload);
      }
      setUserStatus(ACTION_STATUS.SUCCESS)
    } catch (error) {
      if (isUnauthenticated(error) && !doNotRedirect) {
        redirectToLogIn(redirectPath);
      } else {
        dispatch(receiveAmplifyHubUser({ status: "error" }));
      }
      throw error
    }
  }, [dispatch, reduxUser])

  const handleSignOut = async () => {
    try {
      setTriggerHubAuthUseEffect(false);
      await signOut();
      if (location?.pathname !== "/logout") {
        navigate("/logout");
      }
    } catch (error) {
      console.error("Error signing out user ", error);
    }
  };

  return {
    handleSignIn,
    handleSignOut
  };
}