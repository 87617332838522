import { combineReducers } from 'redux';
import { authentication } from './Authentication/reducers';
import * as CommonReducers from './Common/reducers';
import * as MaintenanceReducers from './Maintenance/reducers';
import * as FormReducers from './Forms/reducers';
import * as SearchReducers from './Search/reducers';
import * as IndividualFormReducers from './Form/reducers';
import * as dashboard from './Dashboard/reducers';
import * as ReviewReducers from './Review/reducers';

export default combineReducers({ 
    authentication,
    ...CommonReducers,
    ...MaintenanceReducers,
    ...FormReducers,
    ...SearchReducers,
    ...IndividualFormReducers,
    ...dashboard,
    ...ReviewReducers
})