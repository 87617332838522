import { ERROR_IN_FORM, EXISTING_FORMS, INITIALIZE_TEMPLATE_FORM, RESET_TEMPLATE_FORM } from "../actions-index";

export function templateForm(state = {}, action = {}) {
  const { type, payload = {} } = action;
  
  switch (type) {
    case INITIALIZE_TEMPLATE_FORM:
      return {
        ...state,
        initialState: payload?.initialState,
        formSections: payload?.formSections,
        status: 'initialized',
      };
      case ERROR_IN_FORM:
        return {
          ...state,
          errors: {
            ...state?.errors || {},
            ...action.payload,
          },
          status: 'error',
        };
      case RESET_TEMPLATE_FORM:
        return {};
    default:
      return state;
  }
}

export function existingRecords(state = {}, action = {}) {
  const { type, payload = {} } = action;

  switch (type) {
    case EXISTING_FORMS:
      return {
        ...state,
        ...payload.formType ? {
          ...state?.payload?.formType ?? {},
          [payload.formType]: {
            ...payload
          }
        } : {}
      };
    default:
      return state;
  }
}
