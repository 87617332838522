import {
    GRADUATE_CONTACT_AFFILIATION_COMMENT, GRADUATE_CONTACT_AFFILIATION_COMMENT_RESET, GRADUATE_CONTACT_AFFILIATION_COMMENTS, GRADUATE_CONTACT_AFFILIATION_COMMENTS_RESET,
    GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY, GRADUATE_CONTACT_APPROVE, GRADUATE_CONTACT_DENY, GRADUATE_CONTACT_SUBMIT, GRADUATE_CONTACTS, GRADUATE_CONTACTS_PENDING,
    GRADUATE_FACULTY_APPOINTMENT_COMMENT, GRADUATE_FACULTY_APPOINTMENT_COMMENTS, GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET, GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET,
    GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY, GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET, GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY_RESET,
    GRADUATE_FACULTY_APPROVE, GRADUATE_FACULTY_DENY, GRADUATE_FACULTY, GRADUATE_FACULTY_PENDING, GRADUATE_FACULTY_SUBMIT, GRADUATE_PROGRAM_MAP
} from "../actions-index.js";
import { ACTION_STATUS } from "../util/constants.js";

export function graduateProgramMap(state = {}, action = {}) {
    return (action.type === GRADUATE_PROGRAM_MAP ? action.payload : state);
}

export function graduateContacts(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACTS ? action.payload : state);
}

export function graduateContactsPending(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACTS_PENDING ? action.payload : state);
}

export function graduateContactApprove(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACT_APPROVE ? action.payload : state);
}

export function graduateContactDeny(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACT_DENY ? action.payload : state);
}

export function graduateContactSubmit(state = {}, action = {}) {
    return (action.type === GRADUATE_CONTACT_SUBMIT ? action.payload : state);
}

export function graduateContactAffiliationComment(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_CONTACT_AFFILIATION_COMMENT: {
            const { id, data, message = null, status } = payload;
            if(id) return { ...state, [id]: { data, message, status } };
            return state;
        }
        case GRADUATE_CONTACT_AFFILIATION_COMMENT_RESET:
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        default:
            return state;
    }
}

export function graduateContactAffiliationComments(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_CONTACT_AFFILIATION_COMMENT: {
            const { id, data: comment, status: commentStatus } = payload;
            if(id && commentStatus === ACTION_STATUS.SUCCESS) {
                const commentState = state[id];
                if(!commentState.data) commentState.data = []; // allows a comment to be added when getting comments fails
                commentState.data.unshift(comment); // comments are sorted latest first
                return { ...state, [id]: commentState };
            }
            return state;  
        }      
        case GRADUATE_CONTACT_AFFILIATION_COMMENTS: {
            const { id, data = [], status = "" } = payload;
            if(id) return { ...state, [id]: { data, status } };
            return state;
        }
        case GRADUATE_CONTACT_AFFILIATION_COMMENTS_RESET: {
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        }
        default:
            return state;
    }
}

export function graduateContactAffiliationWorkflowHistory(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY: {
            const { id, data = [], status = "" } = payload;
            if(id) return { ...state, [id]: { data, status } };
            return state;
        }
        case GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY_RESET:
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        default:
            return state;
    }
}

export function graduateFaculty(state = {}, action = {}) {
    return (action.type === GRADUATE_FACULTY ? action.payload : state);
}

export function graduateFacultyPending(state = {}, action = {}) {
    return (action.type === GRADUATE_FACULTY_PENDING ? action.payload : state);
}

export function graduateFacultyApprove(state = {}, action = {}) {
    return (action.type === GRADUATE_FACULTY_APPROVE ? action.payload : state);
}

export function graduateFacultyDeny(state = {}, action = {}) {
    return (action.type === GRADUATE_FACULTY_DENY ? action.payload : state);
}

export function graduateFacultySubmit(state = {}, action = {}) {
    return (action.type === GRADUATE_FACULTY_SUBMIT ? action.payload : state);
}

export function graduateFacultyAppointmentComment(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_FACULTY_APPOINTMENT_COMMENT: {
            const { id, data, message = null, status } = payload;
            if(id) return { ...state, [id]: { data, message, status } };
            return state;
        }
        case GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET: {
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        }
        default:
            return state;
    }
}

export function graduateFacultyAppointmentComments(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_FACULTY_APPOINTMENT_COMMENT: {
            const { id, data: comment, status: commentStatus } = payload;
            if(id && commentStatus === ACTION_STATUS.SUCCESS) {
                const commentState = state[id];
                if(!commentState.data) commentState.data = []; // allows a comment to be added when getting comments fails
                commentState.data.unshift(comment); // comments are sorted latest first
                return { ...state, [id]: commentState };
            }
            return state;
        }      
        case GRADUATE_FACULTY_APPOINTMENT_COMMENTS: {
            const { id, data = [], status = "" } = payload;
            if(id) return { ...state, [id]: { data, status } };
            return state;
        }
        case GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET: {
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        }
        default:
            return state;
    }
}

export function graduateFacultyAppointmentWorkflowHistory(state = {}, action = {}) {
    const { payload = {}, type } = action;
    switch(type) {
        case GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY: {
            const { id, data = [], status = "" } = payload;
            if(id) return { ...state, [id]: { data, status } };
            return state;
        }
        case GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET: {
            if(payload.id) { // reset one
                return { ...state, [payload.id]: {} };
            } else { // reset all
                return {};
            }
        }
        default:
            return state;
    }
}