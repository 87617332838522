import React from "react";
import { MuiX } from "@osu/react-ui";
import { isValid } from "date-fns"

const DatePicker = (props = {}) => {
  const { onChange, ...rest } = props;
  const onDateChange = (value) => {
    if(isValid(value)) onChange(value);
  };
  return (<MuiX.DatePicker {...rest} onChange={onDateChange} />);
};
  
export default DatePicker