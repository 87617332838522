import { Mui } from "@osu/react-ui";
import React, { useEffect } from "react";
import { chunk } from "../../util/functions";

const useStyles = ({ remainingResponseWidth, firstResponseWidth }) => {
  const theme = Mui.useTheme();
  return {
    firstResponse: ({ firstResponseWidth }) => ({
      width: firstResponseWidth,
    }),
    remainingResponse: ({ remainingResponseWidth }) => ({
      width: remainingResponseWidth,
    }),
    chunk: {
      width: "50%",
    },
    label: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(),
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        paddingRight: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.75),
        width: "25%",
      },
      [theme.breakpoints.only("md")]: {
        width: "30%",
        textAlign: "left",
      },
    },
    mediumResponse: {
      paddingBottom: theme.spacing(2),
    },
    value: {
      width: "50%",
      textAlign: "left",
      paddingLeft: theme.spacing(),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(0.75),
        paddingLeft: 0,
        width: "75%",
      },
      [theme.breakpoints.only("md")]: {
        width: "70%",
        textAlign: "left",
      },
    }
  };
};

function StudentInfo(props = {}) {
  const { osuid, getStudentInfo, fetchRecord, setHeading, responses, heading, setErrorMessage, error } = props;
  const medium = Mui.useMediaQuery((theme) => theme.breakpoints.only("md"));
  const sm = Mui.useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let firstResponseWidth = 18;
  let remainingResponseWidth =
    responses.length > 1
      ? (100 - firstResponseWidth) / (responses.length - 1)
      : 0;
  const styles = useStyles({
    remainingResponseWidth: remainingResponseWidth + "%",
    firstResponseWidth: firstResponseWidth + "%",
  });
  useEffect(() => {
    if (fetchRecord) {
      getStudentInfo(osuid);
    }
  }, [fetchRecord, getStudentInfo, osuid]);

  useEffect(() => {
    setHeading(heading)
  }, [heading, setHeading])

  useEffect(() => {
    setErrorMessage(existing => {
      if(!error && existing) {
        return ""
      } else if (error) {
        return error
      }
    })
  }, [error, setErrorMessage])

  let viewResponses = [];

  const stripStylesOnMobile = (
    styles = undefined,
    stylesOnMobile = undefined
  ) => {
    if (sm || medium) return stylesOnMobile;
    return styles;
  };

  responses.forEach(({ responses }, index) => {
    const view = responses.map(({ value, label }, idx) => {
      let labelSx = { fontWeight: "bold" };
      if(index > 0) labelSx = { ...labelSx, ...styles.label };
      let valueSx = {};
      if(index > 0) valueSx = styles.value;
      return (
        <Mui.Box display="flex" key={`${index}-${idx}-student-info-response`}>
          {React.isValidElement(label)
            ? label
            : label && (
                <Mui.Typography
                  component="span"
                  sx={labelSx}
                >
                  {label}
                </Mui.Typography>
              )}
          {value && (
            <Mui.Typography
              component="span"
              sx={valueSx}
            >
              {value}
            </Mui.Typography>
          )}
        </Mui.Box>
      );
    });
    let sizingSx = stripStylesOnMobile(
      index === 0 ? styles.firstResponse : styles.remainingResponse
    );
    if (medium) sizingSx = styles.mediumResponse;

    viewResponses.push(
      <Mui.Box key={viewResponses.length} display="flex" flexDirection="column" sx={sizingSx}>
        {view}
        {index === 0 && sm && <Mui.Divider className="margin-y-2" />}
      </Mui.Box>
    );
  });

  let chunkedResponses = [];
  if (medium) {
    chunkedResponses = chunk(viewResponses, 2);
  }
  if (chunkedResponses?.length) {
    return (
      <Mui.Box display="flex">
        {chunkedResponses.map((views) => (
          <Mui.Box
            alignItems="left"
            justifyContent="left"
            sx={styles.chunk}
          >
            {views}
          </Mui.Box>
        ))}
      </Mui.Box>
    );
  }
  return <Mui.Box display="flex" flexDirection={sm ? "column" : "row"}>
    {viewResponses}
  </Mui.Box>
}

export default StudentInfo;
