import { Mui } from "@osu/react-ui";
import { Link } from "react-router-dom";
import { FORM_TYPE, FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

const FormLink = (props = {}) => {
  const { id, formType, edit } = props;
  const formPath = FORM_TYPE_HTML_FRIENDLY[formType];
  const formTitle = FORM_TYPE[formType];
  let to = `/form/${formPath}`;
  if (!id) {
    to += "/create/";
  } else {
    to += edit ? "/edit/" : "/view/";
    to += id;
  }

  return (
    <Mui.Link component={Link} to={to}>
      {formTitle}
    </Mui.Link>
  );
};

export default FormLink;
