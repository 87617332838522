import { Mui } from "@osu/react-ui";
import React from "react";
import { isObject } from "../../../util/functions";

const pullValue = (value) => (isObject(value) && Object.prototype.hasOwnProperty.call((value || {}), 'value'))
  ? value.value
  : value

const Choices = (props = {}) => {
  const { choices, value: currentValue, idPrefix, onChange, ...rest } = props;
  const formattedValue = pullValue(currentValue)
  return choices.map((choice, index) => {
    const { value, title } = choice;
    let val = pullValue(value)

    return (
      <Mui.FormControlLabel
        key={`${idPrefix}${val}${index}`}
        label={title}
        control={
          <Mui.Checkbox
            name={idPrefix + val}
            checked={formattedValue === val}
            onChange={(event) => {
              const checked = event?.target?.checked;
              const newValue = (checked === true ? value : "N");
              onChange(newValue)
            }}
          />
        }
        {...rest}
      />
    );
  });
};

function Checkbox(props = {}) {
  const { value, onChange, id, label, choices, disabled, className, description, error, dataField, ...rest } = props;

  const choicesProps = {
    choices,
    value,
    idPrefix: id,
    onChange,
    disabled
  };
  
  if(!choices?.length) {
    choicesProps.choices = [{
      value: 'Y',
      title: label
    }]
  }

  return (
    <Mui.FormControl {...rest} error={!!error} component="fieldset" variant="standard" className={className}>
      {!!choices?.length && <Mui.FormLabel component="legend">{label}</Mui.FormLabel>}
      <Mui.FormGroup>
        <Choices {...choicesProps} />
      </Mui.FormGroup>
      {error && <Mui.FormHelperText>{error}</Mui.FormHelperText>}
      {description && <Mui.FormHelperText>
        {description}
        </Mui.FormHelperText>}
    </Mui.FormControl>
  );
}

export default Checkbox;
