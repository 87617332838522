import { isValid } from "date-fns"
import { inputMappings } from "../util/enums"
import { deepCopy } from "../util/functions"

function templateFormReducer(state, action) { 
    let newState = Object.assign({}, state)
    const { payload } = action
    if((payload.id) && !newState?.[payload.id]) {
      newState[payload.id] = {}
    }
    if((payload.id && payload.field) && !newState?.[payload.id]?.[payload.field]) {
      newState[payload.id][payload.field] = {}
    }

    switch (action.type) {
      case "RESET": {
        if(Object.keys(action?.payload?.initialState)) {
          return action?.payload?.initialState
        }
        return state
      }
      case "ERROR": {
        return deepCopy(newState, action?.payload?.errorState || {})
      }
      case "UPDATE": {
        const { payload } = action
        let payloadValue = payload.value
        if(isValid(payloadValue)) {
          payloadValue = payloadValue.toISOString() 
        }
        if(payload?.nested) {
          const nestedId = payload?.nestedId
          if(!newState?.[payload.id]?.[nestedId]) {
            newState[payload.id][nestedId] = {}
          }

          newState[payload.id][nestedId].value = payload?.value?.value
          if(payload?.dataField) {
            newState[payload.id][nestedId].dataField = payload.dataField
          }
          if(newState?.[payload.id]?.[nestedId].error && newState?.[payload.id]?.[nestedId]?.value) {
            newState[payload.id][nestedId].error = ''
          }

          return newState
        }
        if(payload?.id && payload?.field) {
          let existingStateValue = state?.[payload.id]?.[payload.field]?.value
          if(isValid(existingStateValue)) {
            existingStateValue = existingStateValue.toISOString() 
          }

          if(existingStateValue && typeof existingStateValue === 'object') {
            newState[payload.id][payload.field].value = (!Array.isArray(payloadValue) && Object.keys(payloadValue || {}).length) ?
              { ...existingStateValue, ...payloadValue } : 
              (payloadValue || {})
          } else {
            newState[payload.id][payload.field].value = payloadValue
          }
          if(!newState[payload.id][payload.field].dataField && payload.dataField) {
            newState[payload.id][payload.field].dataField = payload.dataField
          }
          if(newState?.[payload.id]?.[payload.field].error && newState?.[payload.id]?.[payload.field]?.value) {
            newState[payload.id][payload.field].error = ''
          }
          const currentValue = newState[payload.id][payload.field]?.value
          if(payload?.branch && (currentValue !== existingStateValue)) {
            if(newState?.[payload.id]?.[payload.branch]) {
              newState[payload.id][payload.branch].value = {}
            }
          }

          if(payloadValue?.alter?.sectionId && payloadValue?.alter?.questionId) {
            const { sectionId, questionId, ...rest } = payloadValue.alter
            newState[sectionId][questionId] = {
              ...rest
            }
          }
        }
        return newState
      }
      case "INITIALIZE": {
        if(action?.payload?.initialState) {
          newState = deepCopy(action?.payload?.initialState || {}, newState)
        }
        return newState
      }
      case "HIDDEN": {
        newState[action?.payload?.id][action?.payload?.field].hidden = action?.payload?.hidden

        return newState
      }
      case "ADD_RESULTS": {
        if(action?.payload?.field && action?.payload?.id) {
          newState = deepCopy(action?.payload?.initialState || {}, newState)

          newState[action.payload.id][action.payload.field].dataField = action.payload.dataField
          newState[action.payload.id][action.payload.field].value = action.payload.value
        }
        return newState
      }
      default:
        return state
    }
  }

function getInitialState(questions = []) {
  if(!Array.isArray(questions) || !questions.length) {
    return {}
  }
  let initialState = {}
  
  questions.forEach(que => {
    if(que.id) {
      initialState[que.id] = {
        value: '',
        required: que.required
      }
    }
  })
  return initialState
}

const formatInitialState = (sections = []) => {
  let formSections = []
  let initialState = {}
  sections.forEach(section => {
    const { id, questions = [], title } = section

    let newSection = {}
    if(id) {
      initialState[id] = {}
      newSection = {
        ...section,
        id,
        title,
        questions: []
      }
      
      questions.forEach(question => {
        const { id: qId, defaultValue, dataFieldMapping, dataField = '', graphql } = question
        if(qId && (question?.type !== inputMappings.subheading)) {
          initialState[id][qId] = {
            value: defaultValue || '',
            dataField,
            graphql,
            dataFieldMapping,
            required: question?.required,
            transformValue: question?.transformValue
          }

          newSection.questions.push(question)

        }
  
      })

      formSections.push(newSection)

    }
  })

  return {
    formSections,
    initialState
  }
}

export {
  templateFormReducer,
  getInitialState,
  formatInitialState
}
