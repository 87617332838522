import { DASH_ALERTS, DASH_ACTIVE_FORMS, DASH_COMPLETED_FORMS } from "../actions-index";

export function dashboard(state = {}, action = {}) {
  const { type, payload = {} } = action;
  let alerts = {}
  let forms = {}

  switch (type) {
    case DASH_ALERTS:
      if (payload?.status) alerts.status = payload.status
      if (Array.isArray(payload?.alerts)) {
        alerts.status = "success"
        alerts.list = payload.alerts
      }
      return {
        ...state,
        alerts
      };
    case DASH_ACTIVE_FORMS:
      if (payload?.status) forms.status = payload.status
      if (Array.isArray(payload?.forms)) {
        forms.status = "success"
        forms.list = payload.forms
      }
      return {
        ...state,
        active: forms
      };
      case DASH_COMPLETED_FORMS:
        if (payload?.status) forms.status = payload.status
        if (Array.isArray(payload?.forms)) {
          forms.status = "success"
          forms.list = payload.forms
        }
        return {
          ...state,
          completed: forms
        };
    default:
      return state;
  }
}
