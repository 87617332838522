import { connect } from 'react-redux';
import NavBar from "../components";
import withRouter from "../../Common/hocs/withRouter";

const mapStateToProps = (state) => {
    const { authentication = {} } = state;
    const { status: authStatus = "", user = {} } = authentication;

    return {
        authStatus,
        user
    };
};

export default withRouter(connect(mapStateToProps, null)(NavBar));
