import { generateClient } from "aws-amplify/api";
import * as CustomQueries from "../graphql/custom";
import * as Queries from "../graphql/queries";
import { generateAction } from "../redux-helpers";
import { DASH_ALERTS, DASH_ACTIVE_FORMS, DASH_COMPLETED_FORMS } from "../actions-index";
import FormLink from "../Common/components/FormLink";
import { gradSchool, programReview } from "./enums";
import { APPROVAL_STATUS, APPROVAL_SUBSTATUS } from "../util/constants";
import { formatDate } from "../util/functions";

const API = generateClient();

export function getDashboard(filter = {}) {
    return async (dispatch, getState) => {
        dispatch(generateAction(DASH_ALERTS, {
            status: "loading"
        }))
        dispatch(generateAction(DASH_ACTIVE_FORMS, {
            status: "loading"
        }))
        dispatch(generateAction(DASH_COMPLETED_FORMS, {
            status: "loading"
        }))
        try {
            const response = await API.graphql({ query: CustomQueries.getDashboard, variables: { filter } });
            const forms = response?.data?.getForms?.forms
            const alerts = response?.data?.getStudentAlerts?.alerts
            dispatch(generateAction(DASH_ALERTS, {
                alerts: alerts || []
            }))
            const { completedForms = [], activeForms = [] } = iterateForms(forms)
            dispatch(generateAction(DASH_ACTIVE_FORMS, {
                forms: activeForms
            }))
            dispatch(generateAction(DASH_COMPLETED_FORMS, {
                forms: completedForms
            }))
        } catch (error) {
            dispatch(generateAction(DASH_ALERTS, {
                status: "error"
            }))
        }
    }
}

function iterateForms(forms = []) {
    let completedForms = [], activeForms = []
    if (Array.isArray(forms)) {
        let filteredForms = forms.filter(form => form.id && form.formType)
        filteredForms.forEach((({ id, formType, submittedDate, lastUpdatedDate, status, substatus, completedDate }) => {            
            if([APPROVAL_STATUS.CANCELLED, APPROVAL_STATUS.COMPLETED, APPROVAL_STATUS.DENIED].includes(status)) {
                let formattedForm = {
                    name: {
                        Component: () => (
                            <FormLink
                                id={id}
                                view
                                formType={formType}
                            />
                        ),
                    },
                    completedDate: formatDate(completedDate, "L")
                }
                completedForms.push(formattedForm)
            } else {
                let programReviewEnum = programReview.notStarted;
                let gradSchoolEnum = gradSchool.notStarted;
                if(status === APPROVAL_STATUS.PENDING) {
                    if(substatus === APPROVAL_SUBSTATUS.DEPT_PENDING) programReviewEnum = programReview.progress;
                    if(substatus === APPROVAL_SUBSTATUS.GS_PENDING) {
                        programReviewEnum = programReview.reviewed;
                        gradSchoolEnum = gradSchool.progress;
                    }
                }
                let formattedForm = {
                    name: {
                        Component: () => (
                            <FormLink
                                id={id}
                                view
                                formType={formType}
                            />
                        ),
                    },
                    submittedDate: (submittedDate ? formatDate(submittedDate, "L") : "-"),
                    lastUpdatedDate: (lastUpdatedDate ? formatDate(lastUpdatedDate, "L") : "-"),
                    programReview: programReviewEnum,
                    gradSchool: gradSchoolEnum
                }
                activeForms.push(formattedForm)
            }
        }))
    }
    return {
        completedForms,
        activeForms
    }
}

export function getStudentAlerts(osuid = "") {
    return async (dispatch, getState) => {
        dispatch(generateAction(DASH_ALERTS, {
            status: "loading"
        }))
        let variables = {}
        const { authentication } = getState()
        const allowOsuId = authentication?.user?.isGraduationAdmin
        if (allowOsuId && osuid) variables.osuid = osuid
        try {
            const response = await API.graphql({ query: Queries.getStudentAlerts, variables });
            const alerts = response?.data?.getStudentAlerts?.alerts
            dispatch(generateAction(DASH_ALERTS, {
                alerts: alerts || []
            }))
        } catch (error) {
            dispatch(generateAction(DASH_ALERTS, {
                status: "error"
            }))
        }
    }
}

export function getForms() {
    return async dispatch => {
        dispatch(generateAction(DASH_ACTIVE_FORMS, {
            status: "loading"
        }))
        dispatch(generateAction(DASH_COMPLETED_FORMS, {
            status: "loading"
        }))
        let filter = {}
        const response = await API.graphql({ query: Queries.getForms });

        const forms = response?.data?.getForms?.forms
        let activeForms = [], completedForms = []
        if (Array.isArray(forms)) {
            let filteredForms = forms.filter(form => form.id && form.formType)
            filteredForms.forEach((({ id, formType, submitted, lastUpdated, status }) => {
                let formattedForm = {
                    name: {
                        Component: () => (
                            <FormLink
                                id={id}
                                view
                                formType={formType}
                            />
                        ),
                    },
                    submittedDate: submitted || "-",
                    lastUpdatedDate: lastUpdated || "-",
                    programReview: programReview.notStarted,
                    gradSchool: gradSchool.notStarted,
                }
                if(status === 'COMPLETE') {
                    completedForms.push(formattedForm)
                } else {
                    activeForms.push(formattedForm)
                }
            }))
        }
        dispatch(generateAction(DASH_ACTIVE_FORMS, {
            forms: activeForms
        }))
        dispatch(generateAction(DASH_COMPLETED_FORMS, {
            forms: completedForms
        }))
    }
}