import { connect } from "react-redux";
import History from "../components/History";
import { completeTask, getHistory, resetState, TASK_ACTION } from "../../actions-index";
import { getFormByType } from "../../Forms/paths";
import { ACTION_STATUS } from "../../util/constants";

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { formId, osuid } = ownProps
  const user = (state?.authentication?.user ?? {});
  const { isEnrollmentAdmin = false, isGraduationAdmin = false, osuid: userId } = user;
  const emplid = (osuid ?? userId);
  const isAdmin = ([isEnrollmentAdmin, isGraduationAdmin].includes(true));
  
  const { columns, history, status } = state?.form?.history?.[formId] ?? {}

  const formType = getFormByType(ownProps?.match?.params?.formType);

  return {
    columns,
    emplid,
    formType,
    history, 
    isAdmin,
    status,
    error: (status === ACTION_STATUS.ERROR) ? "Could not load history for this form." : "",
    taskActionStatus: (state?.taskAction?.status ?? "")
  };
};

const mapDispatchToProps = (dispatch) => ({
  completeTask: (values) => dispatch(completeTask(values)),
  getHistory: ({ emplid, formType, id }) => dispatch(getHistory({ emplid, formType, id })),
  resetTaskAction: () => dispatch(resetState(TASK_ACTION, {}))
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
