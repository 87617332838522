import { uniq } from "lodash";
import { 
    APPLICATION_ROLE, APPLICATION_ROLE_GRAD_FACULTY_PREFIX, APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX,
    DEGREE, DELIMITER, EXTERNAL_ROLE
} from "../util/constants";

function transformExternalUser(user = {}){
    const { externalUserId, emailAddr, firstName, lastName, roleName, externalKeys } = user;
    return Object.freeze({
        externalUserId,
        email: emailAddr,
        fullName: `${firstName} ${lastName}`,
        name: `${firstName} ${lastName}`,
        initials: `${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`.toUpperCase(),
        applicationRoles: [roleName],
        isAuthorized: roleName === EXTERNAL_ROLE,
        isExternal: roleName === EXTERNAL_ROLE,
        externalKeys
    });
}

function transformUser(userDetails) {
    const applicationRoles = Object.freeze((userDetails?.applicationRoles && userDetails.applicationRoles.length > 0) ? userDetails.applicationRoles.split(",") : []);

    const facultyRoles = Object.freeze(applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_FACULTY_PREFIX)));
    const facultyPrograms = Object.freeze(facultyRoles.map(facultyRole => (facultyRole.replace(APPLICATION_ROLE_GRAD_FACULTY_PREFIX, ""))));

    const gradPlanAdminRoles = Object.freeze(applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX)));
    const gradPlanAdminPlans = Object.freeze(gradPlanAdminRoles.map(gradPlanAdminRole => (gradPlanAdminRole.replace(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, ""))));
    const gradPlanAdminPrograms = Object.freeze(uniq(gradPlanAdminRoles.map(gradPlanAdminRole => (gradPlanAdminRole.replace(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, "").split(DELIMITER.ACADEMIC_PLAN)[0]))));

    const gradPlanAssociateRoles = Object.freeze(applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX)));
    const gradPlanAssociatePlans = Object.freeze(gradPlanAssociateRoles.map(gradPlanAssociateRole => (gradPlanAssociateRole.replace(APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX, ""))));

    const isGradStudent = applicationRoles.includes(APPLICATION_ROLE.GRAD_STUDENT);
    const isUnderGradStudent = applicationRoles.includes(APPLICATION_ROLE.UNDERGRAD_STUDENT);

    return Object.freeze({
        academicPlan: userDetails.academicPlan,
        applicationRoles,
        careerNumber: userDetails.careerNumber,
        degree: userDetails.degree,
        email: userDetails.email,
        facultyPrograms,
        facultyRoles,
        fullName: userDetails.fullName,
        gradPlanAdminPlans,
        gradPlanAdminPrograms,
        gradPlanAssociatePlans,
        initials: userDetails.initials,
        isAuthorized: applicationRoles.length > 0,
        isContactApprover: applicationRoles.includes(APPLICATION_ROLE.CONTACT_APPROVER),
        isEnrollmentAdmin: applicationRoles.includes(APPLICATION_ROLE.ENROLLMENT_ADMIN),
        isFaculty: (facultyRoles.length > 0),
        isFacultyApprover: applicationRoles.includes(APPLICATION_ROLE.FACULTY_APPROVER),
        isGradPlanAdmin: (gradPlanAdminRoles.length > 0),
        isGradPlanAssociate: (gradPlanAdminRoles.length === 0 && (gradPlanAssociateRoles.length > 0)),
        isGradStudent,
        isGraduationAdmin: applicationRoles.includes(APPLICATION_ROLE.GRADUATION_ADMIN),
        isInquirer: applicationRoles.includes(APPLICATION_ROLE.INQUIRER),
        isStudent: (isGradStudent || isUnderGradStudent),
        isUnderGradStudent,
        hasDoctoralAcademicPlan: [DEGREE.DMA, DEGREE.PHD].includes(userDetails.degree),
        hasProfessionalAcademicPlan: [DEGREE.AUD, DEGREE.DNP, DEGREE.DPT, DEGREE.EDD, DEGREE.OTD].includes(userDetails.degree),
        name: userDetails.name,
        osuid: userDetails.osuid,
        pcdIndicator: userDetails.pcdIndicator
    });
}

export { transformUser, transformExternalUser }