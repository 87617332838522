import { generateClient } from "aws-amplify/api";
import { transformTasks } from "./transform";
import { TASKS } from "../actions-index";
import * as Queries from "../graphql/queries";
import { ACTION_STATUS, AWS_LAMBDA } from "../util/constants";
import { buildAction } from "../util/functions";

const API = generateClient();

export function getTasks(external = false) {
    return async (dispatch, getState) => {
        const state = await getState();
        const externalKeys = state?.authentication?.user?.externalKeys;
        try {
            dispatch(buildAction(TASKS, { status: ACTION_STATUS.LOADING }));
            const params = {
                query: Queries.getTasks
            }
            if(external && externalKeys){
                params.authMode = AWS_LAMBDA;
                params.authToken = `${externalKeys.ukey}:${externalKeys.akey}`
            }
            const response = await API.graphql(params);
            const tasks = (response?.data?.getTasks?.tasks ?? []);
            dispatch(buildAction(TASKS, { status: ACTION_STATUS.SUCCESS, data: transformTasks(tasks) }));
        } catch(error) {
            console.error("Get Tasks Error: ", error);
            dispatch(buildAction(TASKS, { status: ACTION_STATUS.ERROR }));
        }
    };
}