export const navOverrideStyles = (theme, spacing) => {
    return {
      " #osu-navbar.bux-resets .bux-container, footer.bux-footer > .bux-container": {
        [theme.breakpoints.up("xl")]: {
          maxWidth: theme.breakpoints.values.xl,
          paddingY: 0,
          paddingLeft: theme.spacing(spacing),
          paddingRight: theme.spacing(spacing),
        },
        margin: 0,
        paddingLeft: theme.spacing(spacing),
        paddingRight: theme.spacing(spacing),
        width: "100%"
      },
      " #osu-navbar.bux-resets .bux-container #osu-navname-block img": {
        maxHeight: "2rem",
      },
      " #osu-navname-block, .bux-osu-nav__osu-logo-img": {
       padding: 0,
     },
      "#osu-navlinks a": {
        fontWeight: 700,
      },
      "#osu-navlinks li:last-of-type": {
        marginRight: 0,
      },
      "footer.bux-footer": {
        position: "relative",
        zIndex: 1200
      }
    };
};