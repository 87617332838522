import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
    {
        id: "prof-exam-info",
        title: "Professional Examination Information",
        questions: [
            {
              id: "category-m-approved",
              type: inputMappings.checkbox,
              dataField: "categoryMApproved",
              defaultValue: "N",
              choices: [
                {
                  id: "yes-category-m-approved",
                  title: "Category M Approved",
                  dataField: "categoryMApproved",
                  value: "Y"
                }
              ],
              hidden: true
            },
            {
                id: "term",
                title: "Term",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.term,
                dataField: "termCode",
                filter: {
                  showFromPresent: 2
                }
            },
            {
                title: "Professional Examination Type",
                id: "professional-exam-type",
                required: true,
                type: inputMappings.dropDown,
                dataField: "professionalExamType",
                choices: [
                  {
                    id: "first-professional-exam",
                    title: "First Professional Exam",
                    value: "first-professional-exam",
                  },
                  {
                    id: "second-professional-exam",
                    title: "Second Professional Exam",
                    value: "second-professional-exam",
                  },
                ],
              },
            {
                id: "date-input-group",
                title: "Date of Oral Examination",
                type: inputMappings.datetime,
                dataField: "oralDate",
                startTime: true,
                summary: "The Graduate School must be notified of the proposed date, time and place of the examination at least two weeks in advance.",
                required: true
            },
            {
              id: 'video-conf-component',
              type: inputMappings.checkbox,
              dataField: "videoConference",
              defaultValue: 'N',
              choices: [
                {
                  id: 'yes-video-conf-component',
                  value: 'Y',
                  title: 'There will be a video conference component to this examination.',
                  branch: 'video-conf-platform'
                }
              ]
            },
            {
              id: 'video-conf-platform',
              title: 'Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)',
              dependencies: [
                'video-conf-component'
              ],
              dataField: "videoConferencePlatform",
              type: inputMappings.textInput,
              summary: <Fragment>
                <Mui.Typography variant="body2" className="bold margin-bottom-1">For examinations that are entirely virtual you do not need to include room and building.</Mui.Typography>
                <Mui.Typography variant="body2">If your examination is both virtual and in person please include room and building.</Mui.Typography>
              </Fragment>
            },
            {
              id: 'room-number-building',
              type: inputMappings.inputGroup,
              numberOfResponsesMaximum: 1,
              questions: [
                {
                  id: 'room-num',
                  title: 'Room Number',
                  dataField: "roomNumber",
                  type: inputMappings.textInput
                },
                {
                  id: 'building',
                  title: 'Building',
                  dataField: "building",
                  type: inputMappings.textInput
                }
              ]
            }
        ]
    },
    {
        title: "Professional Examination Committee",
        id: "professional-examination-committee",
        questions: [
          {
            id: "advisor-subheading",
            type: "subheading",
            title: "Advisor Information",
          },
          {
            id: "committee-advisor",
            type: "dropDown",
            title: "Advisor",
            dataField: "committee.advisor",
            summary: null,
            required: true,
            subtype: dropdownSubtypes.advisor,
          },
          {
            id: "co-advisor",
            type: "dropDown",
            title: "Co-Advisor (if applicable)",
            summary: null,
            required: false,
            dataField: "committee.coAdvisor",
            subtype: dropdownSubtypes.coAdvisor,
          },
          {
            id: "committee-members",
            type: "inputGroup",
            title: "Committee Members",
            subtitle: "Graduate Faculty committee members",
            summary:
              "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
            required: null,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 4,
            dependencies: [],
            choices: [],
            dataField: 'committee.members',
            questions: [
              {
                id: "committee-member-info",
                type: "dropDown",
                title: "Faculty Member",
                subtype: dropdownSubtypes.facultyMember,
                summary: null,
                required: false,
                
              },
            ],
          },
          {
            id: "external-members",
            type: "inputGroup",
            title: "External Members",
            subtitle: "Non-Graduate Faculty committee members",
            summary:
              "For each external committee member, completion & submission of Committee and Examination Petition is required.",
            required: null,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 4,
            dependencies: [],
            choices: [],
            dataField: 'committee.externalMembers',
            questions: [
              {
                id: "first-name",
                type: "textInput",
                title: "First Name",
                summary: null,
                required: true,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 1,
                dataField: 'firstName',
                dependencies: [],
                choices: [],
              },
              {
                id: "last-name",
                type: "textInput",
                title: "Last Name",
                summary: null,
                required: true,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 1,
                dataField: 'lastName',
                dependencies: [],
                choices: [],
              },
    
              {
                id: "email",
                type: "textInput",
                title: "Email Address",
                summary: null,
                required: true,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 1,
                dataField: 'email',
                dependencies: [],
                choices: [],
              },
            ],
          },
        ]
      }
];

export const additionalFilters = [ {
  id: "exam-date-after",
  title: "Exam Date After",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "exam-date-before",
  title: "Exam Date Before",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "exam-type",
  title: "Exam Type",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.examType
}];