import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "late-course-petition",
    questions: [
      {
        id: "effective-term",
        title: "Effective Term",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: 'termCode',
        filter: {
          showFromPresent: 1,
          showHistorical: 4
        }
      },
      {
        id: "advisor",
        title: "Advisor",
        dataField: 'advisor',
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.advisor,
        graphql: [
          {
            label: 'OSUID',
            dataField: 'emplid'
          },
          {
            label: 'First Name',
            dataField: 'firstName'
          },
          {
            label: 'Middle Name',
            dataField: 'middleName'
          },
          {
            label: 'Last Name',
            dataField: 'lastName'
          },
          {
            label: 'Faculty E-mail',
            dataField: 'facultyEmail'
          }
        ]
      },
      {
        id: "courses-input-group",
        title: "Courses",
        dataField: 'courses',
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        questions: [
          {
            id: "courses-action-type",
            type: inputMappings.dropDown,
            title: "Action",
            summary: null,
            required: true,
            dataField: 'action',
            choices: [
              {
                id: "add-course",
                title: "Add Course",
                value: "ADD",
              },
              {
                id: "change-audit-to-grade",
                value: "AUDIT_TO_GRADE",
                title: "Change Audit Course to Grade Course",
              },
              {
                id: "change-grade-to-audit",
                value: "GRADE_TO_AUDIT",
                title: "Change Grade Course to Audit Course",
              },
              {
                id: "drop-course",
                title: "Drop Course",
                value: "DROP",
              },
            ],
          },
          {
            id: "courses-ig-number",
            type: inputMappings.textInput,
            title: "Course Number",
            dataField: 'courseNumber',
            summary: null,
            required: true,
          },
          {
            id: "class-number",
            type: inputMappings.textInput,
            title: "Class Number",
            dataField: 'classNumber',
            summary: null,
            required: true,
          },
          {
            id: "subject",
            type: inputMappings.textInput,
            dataField: 'courseSubject',
            title: "Course Subject",
            summary: null,
            required: true,
          },
          {
            id: "credit-hours",
            type: inputMappings.textInput,
            dataField: 'creditHours',
            title: "Credit Hours",
            summary: null,
            required: true,
          },
          {
            id: "instructor-name-n",
            type: inputMappings.textInput,
            dataField: 'instructor',
            title: "Instructor name.#",
            summary: null,
            required: true
          },
          {
            id: "reason",
            type: inputMappings.textInput,
            dataField: 'reason',
            title: "Reason",
            rows: 5,
            summary: null,
            required: true,
            multiline: true,
            gridProps: {
              xs: 12,
              md: 12,
            }
          },
        ]
      },
    ],
  },
];