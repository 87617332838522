import { signInWithRedirect } from 'aws-amplify/auth';

export function redirectToLogIn(redirectPath = ''){
    let path = redirectPath
    
    if(!!window.location.pathname && !path) {
        path = window.location.pathname
        if(window.location.search) {
            path += window.location.search
        }
    }

    signInWithRedirect({
        provider: { custom: process.env.REACT_APP_SAML_PROVIDER },
        customState: (path || '/')
    });
}