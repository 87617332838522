import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
    {
      id: "form-information",
      questions: [
        {
          title: "Graduation Level",
          id: "graduation-information-level",
          required: true,
          type: "dropDown",
          subtype: dropdownSubtypes.graduationLevel, 
          dataField: "gradLevel",
          branch: "graduation-information-plan",
        },
        {
          title: "Graduation Plan",
          id: "graduation-information-plan",
          required: true,
          type: "dropDown",
          dataField: "gradPlan",
          subtype: dropdownSubtypes.graduatePrograms,
          filter: [
            {
              "field":  "graduation-information-level",
              "match": "gradLevel"
            }
          ],
          branch: "advisor-info",
          dependencyHelperText: "Please select a level to select the appropriate plan.",
          dependencies: ["graduation-information-level"]
        },
        {
          id: "advisor-info",
          type: "dropDown",
          title: "Advisor",
          subtype: dropdownSubtypes.advisor,
          dependencies: [
            "graduation-information-plan"
          ],
          dataField: "advisor",
          dependencyHelperText: "Please select a plan to select the appropriate advisor.",
          required: true
        },
        {
         id: "reason-for-withdrawal",
         type: inputMappings.radio,
         title: "Reason for Withdrawal",
         required: true,
         dataField: "reason",
         choices: [
            {
                id: 'medical',
                title: 'Medical',
                value: 'medical',
            },
            {
                id: 'family',
                title: 'Family',
                value: 'family',
            },
            {
                id: 'financial',
                title: 'Financial',
                value: 'financial',
            },
            {
                id: 'professional-opportunity',
                title: 'Professional Opportunity',
                value: 'professional opportunity',
            },
            {
                id: 'military',
                title: 'Military',
                value: 'military',
            },
            {
                id: 'other',
                title: 'Other',
                value: 'other',
                branch: 'other-reason'
            },
            {
                id: 'other-reason',
                type: inputMappings.textInput,
                title: 'Other reason for withdrawal',
                dataField: "otherReason",
                dependencies: [
                    'reason-for-withdrawal'
                ]
            }
         ]
        }
      ]
    },
    {
      Component: () => {
        return <Fragment>
          <Mui.Typography className="margin-bottom-2">If you are a pre-candidacy doctoral, master’s, or a professional doctorate student, please note that you will be evaluated for readmission into the program by the graduate studies committee should you choose to return.</Mui.Typography>
          <Mui.Typography>If you are a post-candidacy doctoral student, a withdrawal from your PhD program means that your candidacy will be cancelled, and a registration lock will be placed on your student account.</Mui.Typography>
        </Fragment>
      }
    }
];