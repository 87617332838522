import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Mui } from "@osu/react-ui";
import { MAIN_ID } from "../../util/constants";

export default function PageNotFound() {
    useEffect(() => {
        document.getElementById(MAIN_ID).focus();
    },[]);  
    return (
        <div>
            <Mui.Typography variant="h2" sx={{ fontSize: "1.5rem", fontWeight: 500, marginBottom: "1rem" }}>Page Not Found</Mui.Typography>
            Return to the <Link to="/">Home</Link> page.
        </div>
    );
}