import { ACTIVE, AFFILIATION, APPOINTMENT, STATUS } from "../util/constants";

const PENDING_NEW = "Pending New";
const PENDING_UPDATE = "Pending Update";

export function transformGraduateContacts(graduateContacts) {
    const transformedGraduateContacts = [];
    for(const graduateContact of graduateContacts) {
        const affiliations = (graduateContact.affiliations ?? []);
        for(const affiliation of affiliations) {
            let statusChanged = false;
            let status = (affiliation.active ?? null);
            const pendingStatus = (affiliation.pendingActive ?? null);
            if(status === null) { // pending new contact
                status = pendingStatus;
            } else {
                statusChanged = (pendingStatus !== null && pendingStatus !== status);
            }

            transformedGraduateContacts.push({
                emplid: graduateContact.emplid,
                name: `${graduateContact.lastName}, ${graduateContact.firstName}`,
                email: graduateContact.email,
                phone: graduateContact.phone,
                affiliation: (AFFILIATION[affiliation.affiliation] ?? ""),
                status: (status === ACTIVE.YES ? STATUS.ACTIVE : STATUS.INACTIVE),
                pendingStatus: (pendingStatus === ACTIVE.YES ? STATUS.ACTIVE : STATUS.INACTIVE),
                statusChanged,
                academicPlan: affiliation.academicPlan,
                pending: affiliation.pending,
                pendingLabel: (affiliation.pending ? (affiliation.pendingNew ? PENDING_NEW : PENDING_UPDATE) : ""),
                pendingNew: affiliation.pendingNew,
                wfId: affiliation.wfId
            });
        }
    }
    return transformedGraduateContacts;
}

export function transformGraduateFaculty(graduateFacultyList) {
    const transformedGraduateFaculty = [];
    for(const graduateFaculty of graduateFacultyList) {
        const appointments = (graduateFaculty.appointments ?? []);
        for(const appointment of appointments) {
            let categoryChanged = false;
            let category = (appointment.category ?? null);
            const pendingCategory = (appointment.pendingCategory ?? null);
            if(category === null) { // pending new faculty member
                category = pendingCategory;
            } else {
                categoryChanged = (pendingCategory !== null && pendingCategory !== category);
            }

            let statusChanged = false;
            let status = (appointment.active ?? null);
            const pendingStatus = (appointment.pendingActive ?? null);
            if(status === null) { // pending new faculty member
                status = pendingStatus;
            } else {
                statusChanged = (pendingStatus !== null && pendingStatus !== status);
            }

            let facultyTypeChanged = false;
            let facultyType = (appointment.facultyType ?? null);
            const pendingFacultyType = (appointment.pendingFacultyType ?? null);
            if(facultyType === null) { // pending new faculty member
                facultyType = pendingFacultyType;
            } else {
                facultyTypeChanged = (pendingFacultyType !== null && pendingFacultyType !== facultyType);
            }

            transformedGraduateFaculty.push({
                emplid: graduateFaculty.emplid,
                name: `${graduateFaculty.lastName}, ${graduateFaculty.firstName}`,
                email: graduateFaculty.email,
                phone: graduateFaculty.phone,
                category: (APPOINTMENT.CATEGORY[category] ?? ""),
                pendingCategory: (APPOINTMENT.CATEGORY[pendingCategory] ?? ""),
                categoryChanged,
                status: (status === ACTIVE.YES ? STATUS.ACTIVE : STATUS.INACTIVE),
                pendingStatus: (pendingStatus === ACTIVE.YES ? STATUS.ACTIVE : STATUS.INACTIVE),
                statusChanged,
                facultyType: (APPOINTMENT.FACULTY_TYPE[facultyType] ?? ""),
                pendingFacultyType: (APPOINTMENT.FACULTY_TYPE[pendingFacultyType] ?? ""),
                facultyTypeChanged,
                pending: appointment.pending,
                pendingLabel: (appointment.pending ? (appointment.pendingNew ? PENDING_NEW : PENDING_UPDATE) : ""),
                pendingNew: appointment.pendingNew,
                program: appointment.program,
                wfId: appointment.wfId
            });
        }
    }
    return transformedGraduateFaculty;
}