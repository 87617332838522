import React from "react";
import { Mui, MuiIcons, MuiStyles } from "@osu/react-ui";
import { Link } from 'react-router-dom';

export default function FormDetail(props) {
    const theme = MuiStyles.useTheme();
    const { form = {} } = props;
    const {name, type, description, canCreate} = form;
    const {expanded, onExpandClick} = props;
    const FilterIcon = (expanded ? <MuiIcons.KeyboardArrowUpRounded fontSize="small" /> : <MuiIcons.KeyboardArrowDownRounded fontSize="small" />)
    const filterIconLabel = `${expanded ? "Hide" : "Show"} Description`;

    return (
        <div>
            <Mui.Card elevation={5}>
                <Mui.CardContent sx={{ paddingBottom: "16px !important" }}>
                    <Mui.Box display="flex">
                        <Mui.Box flexGrow={1} alignSelf="center">
                            <Mui.Link data-testid="header" component={Link} to={`/form/${type}`} sx={{ textDecoration: "none" }}>{name}</Mui.Link>
                        </Mui.Box>
                        {canCreate && 
                            <Mui.Box display="flex" flexDirection="row" flexWrap="wrap" sx={{ "& > * + *": { marginLeft: theme.spacing(1) } }}>
                                <Mui.Button component={Link} to={`/form/${type}/create`} variant="outlined" aria-label="create new"
                                    sx={{ color: theme.palette.success.main, border: "1px solid " + theme.palette.success.main, marginRight: theme.spacing(1) }}>
                                    Create New +
                                </Mui.Button>
                            </Mui.Box>
                        }
                        <Mui.Box>
                            <Mui.IconButton aria-controls="formDetailContent" aria-expanded={expanded} aria-label={filterIconLabel}
                                title={filterIconLabel} size="small" onClick={onExpandClick}>
                                {FilterIcon}
                            </Mui.IconButton>
                        </Mui.Box>
                    </Mui.Box>
                    <Mui.Collapse id="formContent" data-testid="formContent" in={expanded} unmountOnExit>
                        <Mui.Box flexGrow={1} alignSelf="center" sx={{ marginTop: theme.spacing(1) }}>
                            <Mui.Typography data-testid="formDescription" component="p" variant="body2">{description}</Mui.Typography>
                        </Mui.Box>
                    </Mui.Collapse>
                </Mui.CardContent>
            </Mui.Card>
        </div>
    )
}
