/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addGraduateContact = /* GraphQL */ `
  mutation AddGraduateContact($request: GraduateContactRequest!) {
    addGraduateContact(request: $request) {
      academicPlan
      affiliation
      emplid
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const approveGraduateContact = /* GraphQL */ `
  mutation ApproveGraduateContact($wfId: String!) {
    approveGraduateContact(wfId: $wfId) {
      academicPlan
      affiliation
      emplid
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const createGraduateContactAffiliationComment = /* GraphQL */ `
  mutation CreateGraduateContactAffiliationComment(
    $emplid: String!
    $academicPlan: String!
    $affiliation: String!
    $text: String!
  ) {
    createGraduateContactAffiliationComment(
      emplid: $emplid
      academicPlan: $academicPlan
      affiliation: $affiliation
      text: $text
    ) {
      comment {
        emplid
        submittedById
        submittedByName
        submittedDateTime
        text
        academicPlan
        affiliation
        program
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const denyGraduateContact = /* GraphQL */ `
  mutation DenyGraduateContact($wfId: String!) {
    denyGraduateContact(wfId: $wfId) {
      academicPlan
      affiliation
      emplid
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const updateGraduateContact = /* GraphQL */ `
  mutation UpdateGraduateContact($request: GraduateContactRequest!) {
    updateGraduateContact(request: $request) {
      academicPlan
      affiliation
      emplid
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const addGraduateFaculty = /* GraphQL */ `
  mutation AddGraduateFaculty($request: GraduateFacultyRequest!) {
    addGraduateFaculty(request: $request) {
      emplid
      program
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const approveGraduateFaculty = /* GraphQL */ `
  mutation ApproveGraduateFaculty($wfId: String!) {
    approveGraduateFaculty(wfId: $wfId) {
      emplid
      program
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const createGraduateFacultyAppointmentComment = /* GraphQL */ `
  mutation CreateGraduateFacultyAppointmentComment(
    $emplid: String!
    $program: String!
    $text: String!
  ) {
    createGraduateFacultyAppointmentComment(
      emplid: $emplid
      program: $program
      text: $text
    ) {
      comment {
        emplid
        submittedById
        submittedByName
        submittedDateTime
        text
        academicPlan
        affiliation
        program
        __typename
      }
      lastModified
      status
      __typename
    }
  }
`;
export const denyGraduateFaculty = /* GraphQL */ `
  mutation DenyGraduateFaculty($wfId: String!) {
    denyGraduateFaculty(wfId: $wfId) {
      emplid
      program
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const updateGraduateFaculty = /* GraphQL */ `
  mutation UpdateGraduateFaculty($request: GraduateFacultyRequest!) {
    updateGraduateFaculty(request: $request) {
      emplid
      program
      lastModified
      status
      wfId
      __typename
    }
  }
`;
export const approveForm = /* GraphQL */ `
  mutation ApproveForm(
    $emplid: String!
    $formType: String!
    $formId: String!
    $approvalTask: WorkflowApprovalTask!
    $approverEmplid: String
  ) {
    approveForm(
      emplid: $emplid
      formType: $formType
      formId: $formId
      approvalTask: $approvalTask
      approverEmplid: $approverEmplid
    ) {
      emplid
      formId
      formStatus
      formSubstatus
      lastModified
      status
      wfId
      wfType
      __typename
    }
  }
`;
export const cancelForm = /* GraphQL */ `
  mutation CancelForm($emplid: String!, $formType: String!, $formId: String!) {
    cancelForm(emplid: $emplid, formType: $formType, formId: $formId) {
      emplid
      formId
      formStatus
      formSubstatus
      lastModified
      status
      wfId
      wfType
      __typename
    }
  }
`;
export const denyForm = /* GraphQL */ `
  mutation DenyForm(
    $emplid: String!
    $formType: String!
    $formId: String!
    $approvalTask: WorkflowApprovalTask!
  ) {
    denyForm(
      emplid: $emplid
      formType: $formType
      formId: $formId
      approvalTask: $approvalTask
    ) {
      emplid
      formId
      formStatus
      formSubstatus
      lastModified
      status
      wfId
      wfType
      __typename
    }
  }
`;
export const updateApprovalForm = /* GraphQL */ `
  mutation UpdateApprovalForm(
    $emplid: String!
    $formType: String!
    $formId: String!
    $approvalForm: ApprovalFormInput!
  ) {
    updateApprovalForm(
      emplid: $emplid
      formType: $formType
      formId: $formId
      approvalForm: $approvalForm
    ) {
      lastModified
      status
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm($input: FormInput) {
    updateForm(input: $input) {
      form {
        id
        studentid
        academicPlan
        careerNumber
        name
        completedBy
        completedDate
        createdBy
        createdDate
        lastUpdatedBy
        lastUpdatedDate
        submittedBy
        submittedDate
        formType
        status
        substatus
        authorizedPickup {
          firstName
          lastName
          email
          __typename
        }
        action
        termCode
        gradLevel
        endOfSemesterOption
        commencement
        gradPlan
        gradDegree
        finalExamType
        draftDissertationTitle
        oralDate {
          date
          start
          end
          __typename
        }
        videoConference
        videoConferencePlatform
        roomNumber
        building
        mailAddress {
          address1
          address2
          city
          country
          name
          postalCode
          state
          __typename
        }
        committee {
          advisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          coAdvisor {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          gradFacultyRepresentative {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          members {
            emplid
            nameN
            firstName
            lastName
            middleName
            name
            facultyEmail
            facultyNomination
            phone
            address1
            program
            category
            __typename
          }
          externalMembers {
            firstName
            lastName
            email
            __typename
          }
          __typename
        }
        advisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        courses {
          action
          classNumber
          courseNumber
          courseTitle
          courseSubject
          creditHours
          instructor
          institution
          reason
          department
          skType
          termCode
          __typename
        }
        fromTermCode
        toTermCode
        concentrationType
        designation
        currentCareer
        applicationType
        reasonForPetition
        specialization
        academicUnit
        gradProgram
        professionalExamType
        beginDate
        endDate
        pcdIndicator
        completionTerm
        certificatePlan
        requesterId
        reason
        otherReason
        creditType
        isApplicationSubmitted
        cumUndergradGPA
        ohioLink
        delayPeriod
        disclosureOhioLink
        disclosureProRequestUnderstanding
        disclosureProRequestConfirmation
        candidacyType
        requestGradFacultyRep
        currentGradLevel
        proposedAcadLevel
        proposedAdvisor {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        proposedAcadPlan
        ruleWaiverRuleId
        formerCommMemberServingAs
        reasonForPetitionComment
        attachments
        osuid
        justification
        facultyMember {
          emplid
          nameN
          firstName
          lastName
          middleName
          name
          facultyEmail
          facultyNomination
          phone
          address1
          program
          category
          __typename
        }
        street
        phone
        facultyRank
        highestDegree
        osuDegree
        degreeInstitution
        degreeYear
        category
        proposedGradProgram
        proposedCategory
        comments
        categoryMApproved
        creditBlock
        digitalTranscripts
        formatReviewComplete
        __typename
      }
      errors {
        field
        subfield
        type
        __typename
      }
      lastModified
      status
      action
      wfId
      wfType
      __typename
    }
  }
`;
export const handleFormStatusUpdateEvent = /* GraphQL */ `
  mutation HandleFormStatusUpdateEvent(
    $event: AWSJSON
    $workflowStatus: String!
    $workflowId: String!
    $completedCompletingEmplId: String!
    $cancelledCompletingEmplId: String!
    $finalFormStatus: String!
  ) {
    handleFormStatusUpdateEvent(
      event: $event
      workflowStatus: $workflowStatus
      workflowId: $workflowId
      completedCompletingEmplId: $completedCompletingEmplId
      cancelledCompletingEmplId: $cancelledCompletingEmplId
      finalFormStatus: $finalFormStatus
    ) {
      emplid
      formId
      formStatus
      formSubstatus
      lastModified
      status
      wfId
      wfType
      __typename
    }
  }
`;
