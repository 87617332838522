import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PageHeadingContext } from '@osu/react-ui'
import { AppContext } from "../../App/context";
import { ACTION_STATUS } from "../../util/constants";
import useLogin from "../useLogin";

function LogIn(props = {}) {
  const { setHeading } = useContext(PageHeadingContext)
  const { handleSignIn, authStatus } = useContext(AppContext)
  const { error, redirectPath } = useLogin(props)

  useEffect(() => {
    setHeading && setHeading('Log In')
  }, [setHeading])
  
  if (authStatus === ACTION_STATUS.SUCCESS) {
    return <Navigate to={redirectPath || '/'} />;
  } else if(authStatus === ACTION_STATUS.UNAUTHORIZED) {
    return <Navigate to="/unauthorized" />;
  } else if(authStatus === ACTION_STATUS.LOADING) {
    return <div>Logging in...</div>;
  } else if(error || authStatus === ACTION_STATUS.ERROR) {
    return (
      <div>
        There was an error logging you in.
        <button
          className="mt-2"
          variant="contained"
          color="primary"
          aria-label={`Retry Log In}`}
          onClick={() => handleSignIn(redirectPath)}
        >
          Log In
        </button>
      </div>
    );
  } 
  return <Navigate to={redirectPath} replace />
}

export default LogIn;
