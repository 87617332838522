import React, { useCallback, useState } from "react";
import { Fragment } from "react";
import InputMapper from "./Inputs/Mapper";
import { Mui } from '@osu/react-ui'
import { isEmpty } from "lodash";

function determineHidden(currentQuestionId, questions = [], dependencies = [], values = {}, allValues = {}) {
  let requiredChoices = []
  if(Array.isArray(dependencies)) {
    dependencies.forEach(dependency => {
      const matchingQuestion = questions.find(question => {

        return question.id === dependency
      })
      if(matchingQuestion?.choices?.length) {
        
        const choice = matchingQuestion?.choices.find(ch => {
          return ch.branch === currentQuestionId
        })
        requiredChoices.push({
          field: matchingQuestion?.id,
          value: choice?.value
        })
      } else {
        requiredChoices.push({
          field: matchingQuestion?.id,
          value: 'ANY'
        })
      }
    })
  }

  const valueMatchesState = requiredChoices.every(choice => {
    let checkVal = Object.prototype.hasOwnProperty.call(values[choice?.field]?.value || {}, 'value') ? values[choice?.field]?.value?.value : values[choice?.field]?.value
    if(!checkVal) {
      checkVal = allValues && choice?.field && Object.values(allValues).find(val => val[choice.field])?.[choice.field]
      checkVal = Object.prototype.hasOwnProperty.call(checkVal || {}, 'value') ? checkVal.value : checkVal
    } 
    if(choice?.value === 'ANY') {
      return !isEmpty(checkVal)
    }
    return !isEmpty(choice?.value) && (checkVal === choice?.value) 
  })

  return !valueMatchesState
}
const Questions = (props = {}) => {
  const { questions, values, dispatchStepState, disabled, section, allQuestions, allValues, lookupEmplid, setLookupEmplid } = props;

  const updateQuestion= useCallback((val = {}) => {
    dispatchStepState({ 
      type: val.type || "UPDATE", 
      payload: {
        id: section,
        ...val
      }
    })
  }, [dispatchStepState, section])
  if (!Array.isArray(questions) || questions.length === 0) {
    return null;
  }
  
  let jsxQuestions = []
  questions.forEach((question, index) => {
    const { className, dependencies, dataField, results, graphql, hidden = false } = question
    const key = `${question.type}-${question.id}-${index}`
    const value = Object.prototype.hasOwnProperty.call(values?.[question.id] || {}, 'value') ? values?.[question.id]?.value : values?.[question.id]
    const updateInput = ({ value, type, branch, field, nestedId, ...rest }) => {
      if(type) {
        updateQuestion({
          type,
          dataField: rest?.dataField || dataField,
          graphql,
          ...rest
        })
      }
      updateQuestion({
        field: field || question.id,
        value,
        branch,
        dataField: rest?.dataField || dataField,
        graphql,
        nested: rest?.nested,
        nestedId
      })
    }

    let addedProps = { 
      ...question,
      sectionState: values,
      lookupEmplid,
      setLookupEmplid,
      error: values?.[question.id]?.error,
      hidden: dependencies?.length && determineHidden(question.id, allQuestions, dependencies, values, allValues)
    }
    const lastItem  = questions?.length - 1 === index
    if (question.type === "checkbox" && questions.length === 1 && question.required) {
      addedProps.affirmation = true
    }
    if(dependencies?.length) {
      if(!addedProps?.filterState) addedProps.filterState = {}; // could be initialized through question configuration
      dependencies.forEach(field => {
        Object.values(allValues || {}).forEach(val => {
          if(val?.[field]) {
            addedProps.filterState[field] =  val?.[field]
          }
        })
      })
    }
    if(question.type === "subheading") {
      jsxQuestions.push(<Mui.Typography key={key} className="margin-bottom-2">
        {question.title}
      </Mui.Typography>)
    } else {
      let cls = undefined

      if(!lastItem || results?.length || addedProps?.hidden) {
        cls = "margin-bottom-3"
      }

      if(className) cls = className; // question configured with className override

      if(hidden) cls = "display-none";
      
       jsxQuestions.push(<InputMapper 
        disabled={disabled || values?.[question.id]?.disabled}
        key={key}
        {...addedProps} 
        value={value}
        className={cls}
        onChange={(values) => {
          const branch = question?.branch
          if(results?.length) {
            results.forEach(result => {
              if(result?.dataField) {
                dispatchStepState({ 
                  type: "ADD_RESULTS", 
                  payload: {
                    ...result,
                    id: section,
                    value: values?.value?.[result?.field]
                  }
                })
              }
            })
          }
          
          updateInput({
            ...values,
            branch
          })
        }}
      />)
      if(results?.length) {
        let index = 0
        results.forEach(({label,field}) => {
          const relevantData = values?.[question?.id]?.value
          if(relevantData?.[field]) {
            jsxQuestions.push(<Fragment key={key + `result-${index}-${field}`}>
              {label && <Mui.Typography component="p" variant="subtitle2">{label}</Mui.Typography>}
              <Mui.Typography className={!lastItem && "margin-bottom-3"}>{relevantData?.[field]}</Mui.Typography>
            </Fragment>)
          }
          index++
        })
      }
    }

  });

  return <Fragment>
    {disabled && <Mui.Typography>
      The following questions are disabled
    </Mui.Typography>}
    {jsxQuestions}
  </Fragment>;
};

export default Questions;
