import { Mui } from "@osu/react-ui";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { refreshDropdowns } from "../../actions-index";
import { debouncedOptions, dropdownSubtypes, graduateFacultyOptions } from "../../util/enums";
import { getDisplayName } from "../../util/functions";
import { formatDropdownSubtypeFromState } from "../transform";
import useDebounce from '../hooks/useDebounce';

function withDropdownOptions(WrappedComponent) {
  function _withDropdownOptions(props) {
    const { open, callback, subtype, label, id,name, query = '', error, helperText, ...rest } = props;
    const dispatch = useDispatch();
    const isDebounced = debouncedOptions.includes(subtype)
    const [cbOptions, setCbOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(query)
    const debouncedValue = useDebounce(inputValue)
    const optionState = useSelector(({ dropdowns, ...rest }) => {
      return formatDropdownSubtypeFromState({
        subtype, 
        dropdowns: dropdowns
      })
    }, (oldValue, newValue) => (isEqual(oldValue, newValue)))

    const asyncOptions = optionState.options
    const isDebouncedWithNoVal = isDebounced && (!debouncedValue || debouncedValue.length < 2)

    const loadCallback = (open && cbOptions?.length === 0 && callback) && !isDebounced;
    const emptyAsync = !optionState?.status && !asyncOptions?.length
    const loadAsyncOptions = open 
      && !callback 
      && (subtype) 
      && emptyAsync
      && !optionState.loading
      
    let nameMatch = !!props?.value?.value?.emplid 
        && debouncedValue
        && ((debouncedValue === props?.value?.selectedTitle)
        || (debouncedValue.includes(props?.value?.value?.lastName)))

    useEffect(() => {
      let allowQuery = debouncedValue?.length > 1 
      if(!isDebounced || !allowQuery) {
        return;
      }
      const searchHappenedAlready = (optionState?.queries || []).includes(debouncedValue)
      if(allowQuery && graduateFacultyOptions.includes(subtype) && nameMatch) {
        allowQuery = false
      } else if (asyncOptions?.length || optionState.status === 'success') {
        allowQuery = !searchHappenedAlready
      }
      if(allowQuery && !(optionState?.status === 'loading' || optionState?.loading)) {
        dispatch(refreshDropdowns(subtype, { filter: rest?.filter, query: debouncedValue }))
      }
    }, [debouncedValue, dispatch, isDebounced, rest?.filter, nameMatch, optionState?.status, subtype, asyncOptions.length, optionState, props])

    useEffect(() => {
      let active = true;
      if (!loadCallback) {
        return;
      }
      (async () => {
        setLoading(true);
        const response = await callback();
        if (active) {
          setCbOptions(response);
          setLoading(false);
        }
      })();
      return () => {
        active = false;
      };
    }, [loadCallback, callback]);

    useEffect(() => {
      if(!isDebounced && loadAsyncOptions) {
        dispatch(refreshDropdowns(subtype, { filter: rest?.filter }))
      }
    }, [dispatch, loadAsyncOptions, isDebounced, subtype, rest?.filter])
  
    let computedValues = {
      options: [],
      loading: false
    }
    if(callback) {
        computedValues.options = cbOptions
        computedValues.loading = loading || loadCallback
    } else if(subtype) {
      computedValues.options = Array.isArray(asyncOptions) ? asyncOptions : []
      computedValues.loading = !asyncOptions.length && (loadAsyncOptions || optionState?.loading)
    } 
    let newProps = {
        open,
        ...rest,
        ...computedValues
    }
    if(rest?.filterState && newProps?.options?.length) {
      Object.values(rest.filterState).forEach(filter => {
        const df = filter?.dataField
        const lookingFor = df && filter?.value?.[df]
        if(lookingFor) {
          newProps.options = newProps.options.filter(option => {
            const fieldValue = option[df]
            return fieldValue && (fieldValue === lookingFor)
          })
        }
      })
    }

    newProps.renderInput = (params) => {
      return (
        <Mui.TextField
        helperText={helperText || (isDebounced && "Please begin typing to start your search")}
        {...params}
        id={id}
        name={name}
        label={label}
        variant="outlined"
        error={error}
        required={rest?.required}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
                {(newProps.loading && !isDebouncedWithNoVal) ? (
                  <Mui.CircularProgress color="inherit" size={20} />
                  ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          />
          );
        }
        return <WrappedComponent 
          loadingText={(isDebouncedWithNoVal) ? "Begin typing to start your search" : "Loading..."}
          noOptionsText={`No options found, please ${isDebounced ? "try your search again and " : ""}refresh the page.`}
          {...newProps}
          onInputChange={(e, inputVal) => {
            setInputValue(inputVal)
          }
        }
    />
  }
  _withDropdownOptions.displayName = `WithDropdownOptions(${getDisplayName(
    WrappedComponent
  )})`;
  return _withDropdownOptions;
}
export default withDropdownOptions;
