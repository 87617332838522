import { sortBy } from "lodash";
import { FORM_TYPE } from "../util/constants"; 
import { formatDate } from "../util/functions";

export function transformTasks(tasks = []) {
    return sortBy(tasks.map(task => {
        task.creationTime = formatDate(task.creationTime);
        const formTypeLabel = FORM_TYPE[task.formType];
        task.formTypeLabel = formTypeLabel;
        task.description = `${formTypeLabel} for ${task.studentName} - ${task.studentid}`;
        return task;
    }), ["creationTime"]);
}