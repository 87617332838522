import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { CANDIDACY_TYPE, CATEGORY_P } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "app-candidacy-info",
    title: "Candidacy Information",
    questions: [
      {
        id: "term",
        title: "Term",
        required: true,
        dataField: "termCode",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        filter: {
          showFromPresent: 2
        }
      },
      {
        title: "Candidacy Type",
        id: "candidacy-type",  // update checkbox below if this changes
        required: true,
        dataField: "candidacyType",
        type: inputMappings.dropDown,
        choices: [
          {
            id: "first-candidacy",
            title: "First Candidacy",
            value: CANDIDACY_TYPE.FIRST_CANDIDACY,
            alter: {
              sectionId: "app-candidacy-info",
              questionId: "request-rep",
              dataField: "requestGradFacultyRep",
              value: "N"
            }
          },
          {
            id: "second-candidacy",
            title: "Second Candidacy",
            value: CANDIDACY_TYPE.SECOND_CANDIDACY,  // update checkbox below if this changes
            alter: {
              sectionId: "app-candidacy-info",
              questionId: "request-rep",
              dataField: "requestGradFacultyRep",
              value: "Y",
              disabled: true
            }
          },
          {
            id: "supplemental-candidacy",
            title: "Supplemental Candidacy",
            value: CANDIDACY_TYPE.SUPPLEMENTAL_CANDIDACY,
            alter: {
              sectionId: "app-candidacy-info",
              questionId: "request-rep",
              dataField: "requestGradFacultyRep",
              value: "N"
            }
          }
        ]
      },
      {
        id: "request-rep",
        type: inputMappings.checkbox,
        dataField: "requestGradFacultyRep",
        defaultValue: "N",
        choices: [
          {
            id: "grad-faculty-rep",
            value: "Y",
            title: "I would like to request a Graduate Faculty representative (Required for Second Candidacy)."
          }
        ]
      }
    ]
  },
  {
    id: "written-portion",
    title: "Written portion of candidacy examination",
    questions: [
      {
        id: "written-date-begin-date",
        dataField: "beginDate",
        type: inputMappings.date,
        title: "Begin Date",
        required: true
      },
      {
        id: "written-date-end-date",
        dataField: "endDate",
        type: inputMappings.date,
        title: "End Date",
        required: true,
        validation: {
          minDateId: "written-date-begin-date"
        }
      }
    ]
  },
  {
    id: "oral-portion",
    title: "Oral portion of candidacy examination",
    questions: [
      {
        id: "oral-date-input-group",
        title: "Date of Oral Examination",
        type: inputMappings.datetime,
        dataField: "oralDate",
        startTime: true,
        endTime: true,
        required: true,
        validation: {
          begin: "7:30 AM",
          end: "5:30 PM",
          durationInHours: 2,
          weekend: true
        },
        summary: "The oral examination must be on a University business day and must begin no earlier than 7:30 AM, but end no later than 5:30 PM, and must be at least two hours in length."
      },
      {
        id: "video-conf-component",
        type: inputMappings.checkbox,
        defaultValue: "N",
        dataField: "videoConference",
        choices: [
          {
            id: "yes-video-conf-component",
            value: "Y",
            title: "There will be a video conference component to this examination.",
            branch: "video-conf-platform"
          }
        ]
      },
      {
        id: "video-conf-platform",
        title: "Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)",
        dependencies: ["video-conf-component"],
        dataField: "videoConferencePlatform",
        type: inputMappings.textInput,
        required: true,
        summary: (
          <Fragment>
            <Mui.Typography variant="body2" className="margin-bottom-2">
              For examinations that are entirely virtual you do not need to
              include room and building.
            </Mui.Typography>
            <Mui.Typography variant="body2">
              If your examination is both virtual and in person please include
              room and building.
            </Mui.Typography>
          </Fragment>
        ),
        inputProps: {
          maxLength: 255
        }
      },
      {
        id: "room-number-building",
        type: inputMappings.inputGroup,
        numberOfResponsesMaximum: 1,
        questions: [
          {
            id: "room-num",
            title: "Room Number",
            dataField: "roomNumber",
            type: inputMappings.textInput,
            inputProps: {
              maxLength: 255
            }
          },
          {
            id: "building",
            title: "Building",
            dataField: "building",
            type: inputMappings.textInput,
            inputProps: {
              maxLength: 255
            }
          }
        ]
      }
    ]
  },
  {
    title: "Candidacy Committee",
    id: "candidacy-committee",
    questions: [
      {
        id: "advisor-subheading",
        type: inputMappings.subheading,
        title: "Advisor Information"
      },
      {
        id: "committee-advisor",
        type: inputMappings.dropDown,
        dataField: "committee.advisor",
        title: "Advisor",
        summary: null,
        required: true,
        subtype: dropdownSubtypes.advisor,
        filterState: {
          category: {
            dataField: "category",
            value: {
              category: CATEGORY_P
            }
          }
        }
      },
      {
        id: "co-advisor",
        type: inputMappings.dropDown,
        title: "Co-Advisor (if applicable)",
        dataField: "committee.coAdvisor",
        summary: null,
        required: false,
        subtype: dropdownSubtypes.coAdvisor
      },
      {
        id: "committee-members",
        type: inputMappings.inputGroup,
        title: "Committee Members",
        subtitle: "Graduate Faculty committee members",
        summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
        required: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.members",
        questions: [
          {
            id: "committee-member-info",
            type: inputMappings.dropDown,
            title: "Faculty Member",
            subtype: dropdownSubtypes.facultyMember,
            summary: null,
            required: true
          }
        ]
      },
      {
        id: "external-members",
        type: inputMappings.inputGroup,
        title: "External Members",
        subtitle: "Non-Graduate Faculty committee members",
        summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
        required: null,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.externalMembers",
        questions: [
          {
            id: "first-name",
            type: inputMappings.textInput,
            title: "First Name",
            dataField: "firstName",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          },
          {
            id: "last-name",
            type: inputMappings.textInput,
            title: "Last Name",
            dataField: "lastName",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          },
          {
            id: "email",
            type: inputMappings.email,
            title: "Email Address",
            dataField: "email",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: []
          }
        ]
      },
      {
        id: "grad-faculty-rep",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.gradFacultyRepresentative,
        dataField: "committee.gradFacultyRepresentative",
        title: "Graduate Faculty Representative",
        required: false,
        hidden: true
      },
      {
        id: "category-m-approved",
        type: inputMappings.checkbox,
        dataField: "categoryMApproved",
        defaultValue: "N",
        hidden: true,
        choices: [
          {
            id: "category-m-approved-yes",
            value: "Y",
            title: "Category M Approved",
          }
        ]
      }
    ]
  }
];

export const gradChairApprovalQuestions = [
  {
    id: "category-m-approved",
    type: inputMappings.checkbox,
    dataField: "categoryMApproved",
    choices: [
      {
        id: "category-m-approved-yes",
        value: "Y",
        title: "Category M faculty approved"
      }
    ],
    validation: (form, value) => (value !== "Y" ? "Category M Approval is required." : null)
  }
];

export const gradSchoolApprovalQuestions = [
  {
    id: "grad-faculty-rep",
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.gradFacultyRepresentative,
    dataField: "committee.gradFacultyRepresentative",
    title: "Graduate Faculty Representative",
    fullWidth: true,
    filterState: {
      category: {
        dataField: "category",
        value: {
          category: CATEGORY_P
        }
      }
    },
    validation: (form, value) => ((form?.requestGradFacultyRep === "Y" && !value) ? "Graduate Faculty Representative is required." : null)
  }
];

export const additionalFilters = [
  {
    id: "oral-date-after",
    title: "Oral Date After",
    type: inputMappings.date,
    startTime: false,
    endTime: false
  },
  {
    id: "oral-date-before",
    title: "Oral Date Before",
    type: inputMappings.date,
    startTime: false,
    endTime: false
  },
  {
    id: "candidacy-type",
    title: "Candidacy Type",
    required: false,
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.candidacyType
  },
  {
    id: "category-m",
    title: "Category M",
    required: false,
    type: inputMappings.dropDown,
    choices: [
      {
        id: "all-category-m",
        title: "All",
        value: "All"
      },
      {
        id: "yes-category-m",
        title: "Yes",
        value: "Yes"
      },
      {
        id: "no-category-m",
        title: "No",
        value: "No"
      }
    ]
  }
];