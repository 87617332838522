const _concatOrAdd = (styles) => {
    let sx = []
    if(Array.isArray(styles)) {
        sx = sx.concat(styles)
    } else if (styles) {
        sx.push(styles)
    }
    return sx
}


const mergeSxStyles = (originalStyles, newStyles) => {
    let sx = _concatOrAdd(originalStyles)
    sx = sx.concat(_concatOrAdd(newStyles))
    return sx
}

export {
    mergeSxStyles
}