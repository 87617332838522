import React, { Fragment } from "react";
import { Mui, omitReduxProps } from "@osu/react-ui";
import { Link as RouterLink } from "react-router-dom";
import { ACTION_STATUS } from "../../util/constants";

function AuthButton(props = {}) {
  const { status, name, ...rest } = props;
  const remainingProps = omitReduxProps(rest);

  if (status === ACTION_STATUS.LOADING) {
    return (
      <Mui.Typography variant="body2" {...remainingProps}>
        Logging in
      </Mui.Typography>
    );
  }

  if ([ACTION_STATUS.SUCCESS, ACTION_STATUS.UNAUTHORIZED].includes(status)) {
    return (
      <Mui.Box sx={{ alignItems: "center", display: "flex", flexWrap: "wrap", flexDirection: "row" }} {...remainingProps}>
        {name && 
          <Fragment>
             <Mui.Typography variant="body2" sx={{ overflowWrap: "anywhere" }}>{name}</Mui.Typography>
             <Mui.Typography variant="body2">&nbsp;|&nbsp;</Mui.Typography>
          </Fragment>
        }
        <Mui.Box sx={{ width: "3.8rem" }}>
          <Mui.Link component={RouterLink} to="/logout">Log Out</Mui.Link>
        </Mui.Box>
      </Mui.Box>
    );
  }

  return (
    <Mui.Link component={RouterLink} to="/login">
      Log In
    </Mui.Link>
  );
}

AuthButton.defaultProps = {
  color: "inherit",
};

export default AuthButton;