import { Mui, TOC } from "@osu/react-ui";
import React, { useState } from "react";
import Questions from "../../Common/components/Questions";

const validateDependsOn = (formSections, stepState, dependsOn) => {
    if (dependsOn) {
      const findDependentSection = formSections.find(
        (section) => section.id === dependsOn
      );
    return findDependentSection.validation(stepState?.[dependsOn]);
  }
  return true;
};
const Paper = (props = {}) => (
  <Mui.Paper
    className="display-flex flex-direction-column padding-5 margin-bottom-2"
    {...props}
  />
);

const Parent = (props = {}) => {
  const { heading, index, withTOC, id, showSection, ...rest } = props;
  let passProps = rest;
  if (!showSection) {
    passProps.className = "display-none";
  }
  if (withTOC && heading) {
    return (
      <Paper
        component={TOC.Section}
        loading={!heading}
        heading={heading}
        sortPriority={index}
        id={`toc-${index}-${id}`}
        {...passProps}
      />
    );
  }
  return <Paper id={id} {...passProps} />;
};

function Sections(props = {}) {
  const { withTOC, dispatchStepState, stepState, formSections } = props;
  const [lookupEmplid, setLookupEmplid] = useState("")

  const allQuestions = [].concat.apply([], formSections.map(({ questions: qsts = []}) => qsts));
  return formSections.map(
    ({ dependsOn, title, id, questions, Component, summary }, index) => {
      const showSection = validateDependsOn(formSections, stepState, dependsOn);
      return (
        <Parent
          showSection={showSection}
          key={`parent-${index}-${id}`}
          withTOC={withTOC}
          id={id}
          heading={title}
          index={index}
        >
          {!withTOC && title && (
            <Mui.Typography className="margin-bottom-1" variant="h2">
              {title}
            </Mui.Typography>
          )}
          {summary && (
            <Mui.Typography className="margin-bottom-2">
              {summary}
            </Mui.Typography>
          )}
          {Component ? (
            <Component />
          ) : (
            <Questions
              lookupEmplid={lookupEmplid}
              setLookupEmplid={setLookupEmplid}
              section={id}
              values={stepState?.[id]}
              dispatchStepState={dispatchStepState}
              questions={questions}
              allQuestions={allQuestions}
              allValues={stepState}
            />
          )}
        </Parent>
      );
    }
  );
}
export default Sections;
