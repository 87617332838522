import React from "react";
import { MuiStyles } from "@osu/react-ui";
import FormDetail from "./FormDetail";
import { formData } from "../../util/enums";

export default function List(props) {
    const data = props?.data || formData;
    const listType = props?.match?.params?.listType;
    
    const theme = MuiStyles.useTheme();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event) => {
        let isExpanded = panel === expanded;
        setExpanded(isExpanded ? false : panel);
    };

    const formContainerOpenSx = { marginBottom: theme.spacing(3), marginTop: theme.spacing(3), transition: "margin 500ms" };
    const formContainerClosedSx = { marginBottom: theme.spacing(1), marginTop: theme.spacing(1), transition: "margin 500ms" };

    return (
        <div>
            {data?.[listType]?.forms.map((form, index)=>{
               return (
                    <div key={index} sx={expanded === `panel${index}` ? formContainerOpenSx : formContainerClosedSx}>
                        <FormDetail form={form} expanded={expanded === `panel${index}`} onExpandClick={handleChange(`panel${index}`)}/>
                    </div>
                )
            })}
        </div>
    );
}