import { useState, useEffect } from 'react';
const TYPE_INTERVAL = 500

function useDebounce(value) {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setState(value)
        }, TYPE_INTERVAL);
        
        return () => clearTimeout(handler);
    }, [value]);

    return state
}
export default useDebounce;