import React, { useMemo } from "react";
import { Helmet, useDarkMode } from "@osu/react-ui";

const AppleTouchIcon = (props = {}) => {
  const { iconSizes } = props;
  const { darkMode } = useDarkMode()
  const icons = useMemo(() => {
    return iconSizes.map((size, index) => {
      let path = `${process.env.PUBLIC_URL}/images/touch-icons/`;
      path += `block-o-${darkMode ? "light" : "normal"}-${size}.png`;
      return (
          <link
            key={`apple-touch-${index}`}
            rel="apple-touch-icon"
            sizes={size}
            href={path}
          />
        );
    })
  }, [darkMode, iconSizes])
  
  return (
    <Helmet>
      {icons}
    </Helmet>
  );
};

AppleTouchIcon.defaultProps = {
  iconSizes: ["180x180", "120x120", "167x167", "152x152", "1024x1024", "144x144", "512x512", "192x192"],
};

export default AppleTouchIcon;
