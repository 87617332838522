import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getPerson as getPersonAction } from "../../actions-index";
import { useSelector } from "react-redux";
import { isValidEmplidLength } from "../../util/functions";

function useLookup() {
  const dispatch = useDispatch();
  const [localStatus, setLocalStatus] = useState("");
  const [emplid, setEmplidId] = useState("");
  const storedPerson = useSelector((state) => {
    return state.person
  });

  const getPerson = (emplid) => {
    if(emplid) {
      setLocalStatus("loading");
      setEmplidId(emplid);
      if (isValidEmplidLength(emplid)) {
        dispatch(getPersonAction(emplid));
        setLocalStatus("");
      } else {
        setLocalStatus("error");
      }
    }
  };

  let person = useMemo(() => {
      if (
        emplid &&
        storedPerson?.data?.emplid &&
        storedPerson?.data?.emplid === emplid
      ) {
        return storedPerson?.data
      } else if (storedPerson?.archive?.[emplid]) {
        return storedPerson.archive[emplid]
      }
      return {}
  }, [emplid, storedPerson?.data, storedPerson?.archive])

  return {
    getPerson,
    person: person || {},
    setPersonEmplid: setEmplidId,
    status: localStatus || storedPerson?.status
  };
}

export default useLookup;
