import React, { useContext, useEffect } from "react";
import { PageHeadingContext } from "@osu/react-ui";
import { AppContext } from "../../App/context";

const LogOut = () => {
  const { handleSignOut, authStatus } = useContext(AppContext);
  const { setHeading } = useContext(PageHeadingContext);

  useEffect(() => {
    setHeading("Log Out");
  }, [setHeading]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted || authStatus === 'cleared') {
      return;
    }

    const signOut = async () => {
      try {
        await handleSignOut();
      } catch (error) {
        console.error("error", error);
      }
    };

    signOut();
    return () => {
      isMounted = false;
    };
  }, [authStatus, handleSignOut]);

  return <div>You are logged out</div>;
};

export default LogOut;
