import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { PageHeadingContext } from '@osu/react-ui'
import { AppContext } from "../../App/context";
import { ACTION_STATUS } from "../../util/constants";
import { generateUrlSeachObj } from "../../util/functions";

function ExternalLogIn(props = {}) {
  const { getExternalUserInfo, location, redirectPath} = props;
  const { setHeading } = useContext(PageHeadingContext)
  const { authStatus } = useContext(AppContext)
  const [error, setError] = useState(false)

  useEffect(() => {
    let isMounted = true;
    if (!isMounted || [ACTION_STATUS.LOADING, ACTION_STATUS.SUCCESS].includes(authStatus)) {
      return;
    }

    const signIn = async () => {
      try {
        let {ukey, akey} = generateUrlSeachObj(location.search);
        await getExternalUserInfo(ukey, akey);
      } catch (err) {
        console.error("error", err);
        setError(true);
      }
    };

    signIn();
    
    return () => {
      isMounted = false;
    };
  });
  
  useEffect(() => {
    setHeading && setHeading('External Log In')
  }, [setHeading])
  
  if (authStatus === ACTION_STATUS.SUCCESS) {
    return <Navigate to={redirectPath || '/'} />;
  } else if(authStatus === ACTION_STATUS.UNAUTHORIZED) {
    return <Navigate to="/unauthorized" />;
  } else if(!error && authStatus === ACTION_STATUS.LOADING) {
    return <div>Logging in...</div>;
  } else if(error || authStatus === ACTION_STATUS.ERROR) {
    return (
      <div>
        There was an error logging you in. 
        Please use the link you were provided via email or get assistance from your graduate school contact. 
      </div>
    );
  } else {
    return null;
  }
}

export default ExternalLogIn;
