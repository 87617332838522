import { connect } from "react-redux";
import { ACTION_STATUS } from "../../../../util/constants";
import { getStudentInfo } from "../../../actions";
import Person from "../components/";

const mapStateToProps = (state, ownProps) => {
  const { studentInformation, authentication } = state
  const studentid = ownProps?.studentid || authentication?.user?.osuid
  const user = studentid ? studentInformation?.[studentid] : {}
  
  return {
    studentid,
    fetchData: studentid && !Object.keys(user || {}).length,
    user: {
      loading: studentInformation?.status === ACTION_STATUS.LOADING,
      ...user,
      street: user?.address?.address1 ?? "",
      phone: user?.phone ?? "",
      mailingAddress: user?.address
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentInfo: (osuid) => dispatch(getStudentInfo(osuid))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Person);
