import{ useMemo } from "react";
import { Mui } from "@osu/react-ui";

const total = 12

const useGridSizing = () => {
    const theme = Mui.useTheme()
    const md = Mui.useMediaQuery(theme.breakpoints.only('md'))
    const lgXl = Mui.useMediaQuery(theme.breakpoints.between('lg', 'xl'))
    const xlUp = Mui.useMediaQuery(theme.breakpoints.up('xl'))

    const primary = useMemo(() => {
        if(xlUp) {
            return 8
        } else if(lgXl) {
            return 7
        } else if (md) {
            return 6
        } 
        
        return total
    }, [lgXl, md, xlUp])
    
    const secondary = (primary === total) ? total : (total - primary)

    return {
        nogrid: primary === total,
        primary,
        secondary,
        gap: 4,
        divider: 2,
        padding: 2
    }
}

export default useGridSizing