import { connect } from "react-redux";
import Responses from "../components/Responses";
import withRouter from "../../Common/hocs/withRouter";
import formDefinitions from "../../Forms/paths";
import { getSections } from "../../actions-index";

const mapStateToProps = (state = {}, ownProps = {}) => {
  const {formId, match} = ownProps;
  const formattedValues = state?.form?.formatted?.[formId]?.responses
  const formType = ownProps?.match?.params?.formType;
  const relatedForm = formDefinitions?.[formType]
  const osuid = match?.params?.osuid
  const hasPk = relatedForm?.pk
  const formInRedux = state?.form?.[formId] && formattedValues?.length
  let fetchData = true
  
  if(formInRedux || !formType) {
    fetchData = false 
  } else if(hasPk) {
    fetchData = osuid && formId
  } else {
    fetchData = !!formId
  }

  return {
    title: relatedForm?.title,
    form: state?.form?.[formId],
    responses: formattedValues,
    osuid,
    formType: relatedForm?.formType,
    fetchData: fetchData && formId,
    formId
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSections: (filter) => dispatch(getSections(filter))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Responses));
