import React, { useContext } from "react";
import {
  PageHeading,
  withPageHeading,
  TableOfContents,
  Mui,
  MuiStyles,
  TOC
} from "@osu/react-ui";
import Routes from "../../Routes/components";
import { mergeSxStyles } from "../../styles/util";
import { PageHeadingContext } from "@osu/react-ui";

function Main(props = {}) {
  const { className, unauthorized, loading, spacingTop, sx, ...rest } = props;
  const { exists } = useContext(TOC.Context);
  const { options } = useContext(PageHeadingContext);
  const theme = MuiStyles.useTheme();

  const rootSx = mergeSxStyles(sx, {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    minHeight: "calc(100vh - var(--nav-height) - var(--footer-height))"
  });

  const smUp = Mui.useMediaQuery(theme => theme.breakpoints.up("sm"));
  const smDown = Mui.useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
      <Mui.Box flexGrow={100} className={className} sx={rootSx} {...rest}>
        {smUp &&
          <Mui.Box>
            <TableOfContents
              spacingTop={spacingTop}
              PaperProps={{
                sx: { zIndex: theme.zIndex.appBar - 2 }
              }}
              StylizedLinkProps={{ color: "primary" }}
            />
          </Mui.Box>
        }
        <Mui.Container className="margin-y-4" maxWidth="xl">
          {smUp &&
            <Mui.Box>
              <PageHeading {...options} />
            </Mui.Box>
          }
          {smDown &&
            <Mui.Box>
              <header className="display-flex">
                <PageHeading {...options} />
              </header>
              {exists && <TableOfContents variant="popover" />}
            </Mui.Box>
          }
          <Routes />
        </Mui.Container>
      </Mui.Box>
  );
}

Main.defaultProps = {
  className: "",
  sx: {},
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(Main);