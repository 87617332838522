import { Mui, MuiIcons } from "@osu/react-ui";
import React, { Fragment } from "react";
import InputGroupRow from "./InputGroupRow";
import PropTypes from 'prop-types'

function InputGroup(props = {}) {
  const { label, summary, onChange, subtitle, id: inputGroupId, numberOfResponsesMaximum, questions, disabled, error, className, value } = props;

  let renderableSubtitle = ""
  if(label && subtitle) renderableSubtitle += " - "
  if(subtitle) renderableSubtitle += subtitle

  const singleRow = numberOfResponsesMaximum === 1 
  return (
    <div id={inputGroupId} className={className}>
      {label && <Mui.Typography component="label" variant="subtitle1">{label}</Mui.Typography>}
      {error && <Mui.FormHelperText error={true}>{error}</Mui.FormHelperText>}
      {(renderableSubtitle) && <Mui.FormLabel color="secondary">{renderableSubtitle}</Mui.FormLabel>}
      {summary && <Mui.Typography className="margin-y-1" variant="body2" color="secondary">{summary}</Mui.Typography>}
      {Array.isArray(value) && value.map((row, index) => {
        const { id } = row

        const disableDeletion =
          index === 0 &&
          value.length === 1 &&
          !row.name &&
          !row.relationship;
        return (
          <Fragment key={`${id}-${index}`}>
            {!singleRow && <Mui.FormHelperText className="margin-bottom-1">
              {label ? label + " " : ""}Response {index + 1}
            </Mui.FormHelperText>}
            <InputGroupRow
              hideDeletion={singleRow}
              disableDeletion={disableDeletion}
              error={error}
              id={id}
              row={row}
              index={index}
              updateRow={(newRow = {}) => onChange({ type: "UPDATE", payload: { id, ...newRow } })}
              deleteRow={(newRow = {}) => onChange({ type: "DELETE", payload: { id } })}
              questions={questions}
              disabled={disabled}
              className={label && (!summary) ? "margin-top-2" : undefined}
            />
          </Fragment>
        );
      })}
      {!singleRow && ((value?.length < numberOfResponsesMaximum) ? (
        <Mui.Button
          onClick={(event) => onChange({ type: "ADD" })}
          variant="outlined"
          disabled={disabled}
          startIcon={<MuiIcons.Add />}
        >
          Add
        </Mui.Button>
      ) : (
        <Mui.Typography variant="body2" sx={{ fontStyle: 'italic' }}>
          You have reached the maximum number of responses.
        </Mui.Typography>
      ))}
    </div>
  );
}

InputGroup.defaultProps = {
  label: ''
};

InputGroup.propTypes = {
  numberOfResponsesMaximum: PropTypes.number,
  numberOfResponsesMinimum: PropTypes.number
}

export default InputGroup;