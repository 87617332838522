import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../App/context";
import { ACTION_STATUS } from "../util/constants";
import { authenticationFlowPaths } from "../Routes/paths";

function confirmRedirectPath({ redirectPath = "/" }) {
    const redirectExclusions = authenticationFlowPaths.map(({ path }) => path)
    const redirectParts = redirectPath.split('?')
    if(redirectParts[0] &&  !redirectExclusions.includes(redirectParts[0])) {
      return redirectPath
    }
  }

function useLogin(props = {}) {
  const { redirectPath, ...options } = props ?? {};
  const { handleSignIn, authStatus } = useContext(AppContext)
  const location = useLocation()
  const [error, setError] = useState(false)
  const rp = location?.state?.redirectPath || redirectPath
  const pathToRedirectTo = rp && confirmRedirectPath({
    pathname: location?.pathname,
    redirectPath: rp
  })
  useEffect(() => {
    const returnedFromAuth = (location?.search ?? '').startsWith("?code=")
    if (returnedFromAuth 
        || error 
        || [ACTION_STATUS.ERROR, ACTION_STATUS.LOADING, ACTION_STATUS.SUCCESS].includes(authStatus)) {
      return;
    }

    const signIn = async () => {
      try {
        await handleSignIn(pathToRedirectTo, options);
      } catch (error) {
        console.error("error", error);
        setError(true);
      }
    };

    if(authStatus !== ACTION_STATUS.UNAUTHORIZED) signIn();
  }, [authStatus, error, handleSignIn, location?.search, options, pathToRedirectTo]);

  return {
    redirectPath: pathToRedirectTo,
    error
  }
}

export default useLogin;
